import React, { useEffect, useState } from "react";

import { Toast, ToastHeader, ToastBody } from "reactstrap";

import logo from "../../Assets/images/ClearLaw.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ToastSuccess = ({ toastContent, status, setToast }) => {
  const [toast, settoast] = useState(true);

  const toggleToast = () => {
    settoast(!toast);
  };

  useEffect(() => {
    setTimeout(() => {
      settoast(false);
      setToast(false);
    }, 3000);
  }, []);

  console.log(status, "hello");

  return (
    <React.Fragment>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast}>
          <ToastHeader className="text-center">
            <img src={logo} alt="" className="me-2" height="18" />
          </ToastHeader>
          <ToastBody
            color="white"
            className={
              status == "success"
                ? "bg-success text-white"
                : "bg-red text-white"
            }
          >
            {/* Hello, world! This is a toast message. */}
            {toastContent}
          </ToastBody>
        </Toast>
      </div>
    </React.Fragment>
  );
};

export default ToastSuccess;
