import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link, useNavigate } from "react-router-dom";
import List from "list.js";
// Import Flatepicker
import Flatpickr from "react-flatpickr";
import { getRequest, postRequest } from "../../helpers/api_helper1";
import Loader from "../../components/Common/loader";
import { toast } from "react-toastify";

const Manage_Testimonial = () => {
  const [managePageData, setManagePageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  document.title =
    "Manage Content Page | Upzet - React Admin & Dashboard Template";

  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {}

  const [modal_delete, setmodal_delete] = useState(false);
  function tog_delete() {
    setmodal_delete(!modal_delete);
  }

  const handleActive = async (status, id) => {
    let updatedStatus = status == 0 ? 1 : 0;
    let data = { status: updatedStatus, id: id };
    setIsLoading(true);
    try {
      const res = await postRequest({
        url: "/admin/update-testimonial-status",
        data: data,
      });
      const response = JSON.parse(JSON.stringify(res));
      console.log(response);
      if (response?.status === 200) {
        setIsLoading(false);

        loadContent();
        if (status == 1) {
          toast.success("Inactive successfully");
        } else {
          toast.success("Active successfully");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleDelete = async (id) => {
    const result = window.confirm("Do you want to delete ?");

    if (result) {
      setIsLoading(true);
      let body = {
        id: id,
        delete_for: "testinmonial",
      };
      await postRequest({
        url: "/admin/delete-tabuler-data",
        data: body,
      }).then((response) => {
        if (response.status === 200) {
            toast.success("Delted successfully");
          setIsLoading(false);
          loadContent();
        }
      });
    }
  };

  useEffect(() => {
    // setToken(localStorage.getItem("cms_token"));
    loadContent();
  }, []);

  const loadContent = async () => {
    setIsLoading(true);
    await getRequest({ url: "/admin/get-testimonial" })
      .then((response) => {
        console.log(response.data);
        setManagePageData(response.data?.reverse());
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Clear Law" breadcrumbItem="Manage Testimonial" />

          {/* //Table section */}

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Page Listing</h4>
                </CardHeader>

                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          <Button
                            color="success"
                            className="add-btn"
                            onClick={() => navigate("/add-testimonial")}
                            id="create-btn"
                          >
                            <i className="fa fa-plus  me-1"></i> Add
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap"
                          id="customerTable"
                        >
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "50px" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                  />
                                </div>
                              </th>
                              <th className="sort" data-sort="page_name">
                                Name
                              </th>
                              <th className="sort" data-sort="url">
                                Url
                              </th>
                              <th className="sort" data-sort="url">
                                Rating
                              </th>
                              <th className="sort" data-sort="url">
                                Image
                              </th>
                              <th className="sort" data-sort="status">
                                Status
                              </th>
                              <th className="sort" data-sort="action">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {managePageData?.map((item) => (
                              <>
                                <tr>
                                  <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value="option1"
                                      />
                                    </div>
                                  </th>
                                  <td
                                    className="id"
                                    style={{ display: "none" }}
                                  >
                                    <Link
                                      to="#"
                                      className="fw-medium link-primary"
                                    >
                                      #VZ2101
                                    </Link>
                                  </td>
                                  <td className="customer_name">
                                    {item?.name}
                                  </td>
                                  <td className="email">{item?.url}</td>
                                  <td className="email">{item?.rating}</td>
                                  <td>
                                    <img
                                      // className="avatar-xs rounded-circle"
                                      src={item.image}
                                      alt="Card image cap"
                                      width="50"
                                      height="65"
                                    />
                                  </td>

                                  <td className="status">
                                  {item.status == 1 ? (
                                      <span className="badge badge-pill badge-soft-success text-uppercase">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge badge-pill badge-soft-danger text-uppercase">
                                        Inactive
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="edit">
                                        <Link
                                          to={`/edit-testimonial/${item.id}`}
                                          type="button"
                                          className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                        >
                                          <i className="fa fa-pencil"></i>
                                          
                                        </Link>
                                      </div>
                                      <div className="remove">
                                        <button
                                          className="btn btn-sm btn-danger btn-rounded"
                                          onClick={() => {
                                            handleDelete(item.id);
                                          }}
                                        >
                                          {" "}
                                          <i className="fa fa-trash "></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-warning btn-sm btn-rounded  ms-2"
                                          onClick={() => {
                                            handleActive(item.status, item.id);
                                          }}
                                        >
                                          <i className="fa fa-ban"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                        <div className="noresult" style={{ display: "none" }}>
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            <p className="text-muted mb-0">
                              We've searched more than 150+ Orders We did not
                              find any orders for you search.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Add Modal */}
          <Modal
            isOpen={modal_list}
            toggle={() => {
              tog_list();
            }}
            centered
          >
            <ModalHeader
              className="bg-light p-3"
              id="exampleModalLabel"
              toggle={() => {
                tog_list();
              }}
            >
              {" "}
              Add Customer{" "}
            </ModalHeader>
            <form className="tablelist-form">
              <ModalBody>
                <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                  <label htmlFor="id-field" className="form-label">
                    ID
                  </label>
                  <input
                    type="text"
                    id="id-field"
                    className="form-control"
                    placeholder="ID"
                    readOnly
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="customername-field" className="form-label">
                    Customer Name
                  </label>
                  <input
                    type="text"
                    id="customername-field"
                    className="form-control"
                    placeholder="Enter Name"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="email-field" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email-field"
                    className="form-control"
                    placeholder="Enter Email"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    id="phone-field"
                    className="form-control"
                    placeholder="Enter Phone no."
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="date-field" className="form-label">
                    Joining Date
                  </label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      dateFormat: "d M, Y",
                    }}
                    placeholder="Select Date"
                  />
                </div>

                <div>
                  <label htmlFor="status-field" className="form-label">
                    Status
                  </label>
                  <select
                    className="form-control"
                    data-trigger
                    name="status-field"
                    id="status-field"
                  >
                    <option value="">Status</option>
                    <option value="Active">Active</option>
                    <option value="Block">Block</option>
                  </select>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => setmodal_list(false)}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    id="add-btn"
                  >
                    Add Customer
                  </button>
                  {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                </div>
              </ModalFooter>
            </form>
          </Modal>

          {/* Remove Modal */}
          <Modal
            isOpen={modal_delete}
            toggle={() => {
              tog_delete();
            }}
            className="modal fade zoomIn"
            id="deleteRecordModal"
            centered
          >
            <div className="modal-header">
              <Button
                type="button"
                onClick={() => setmodal_delete(false)}
                className="btn-close"
                aria-label="Close"
              >
                {" "}
              </Button>
            </div>
            <ModalBody>
              <div className="mt-2 text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/gsqxdxog.json"
                  trigger="loop"
                  colors="primary:#f7b84b,secondary:#f06548"
                  style={{ width: "100px", height: "100px" }}
                ></lord-icon>
                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                  <h4>Are you Sure ?</h4>
                  <p className="text-muted mx-4 mb-0">
                    Are you Sure You want to Remove this Record ?
                  </p>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button
                  type="button"
                  className="btn w-sm btn-light"
                  onClick={() => setmodal_delete(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn w-sm btn-danger "
                  id="delete-record"
                >
                  Yes, Delete It!
                </button>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Manage_Testimonial;
