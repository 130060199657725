const SidebarData = [
  {
    label: "Menu",
    isMainMenu: true,
  },
  {
    label: "Dashboard",
    icon: "fa fa-home",
    url: "/dashboard",
    issubMenubadge: true,
    // bgcolor: "bg-primary",
    // badgeValue: "3",
  },
  {
    label: "Company Information",
    icon: "fa fa-info",
    isHasArrow: true,
    url: "/manage-company-information",
  },

  // {
  //     label: "Email",
  //     icon: "mdi mdi-email-outline",
  //     subItem: [
  //         { sublabel: "Inbox", link: "/#" },
  //         { sublabel: "Read Email", link: "/#" },
  //         { sublabel: "Email Compose", link: "/#" },
  //     ],
  // },
  {
    label: "CMS",
    isMainMenu: true,
  },

  {
    label: "CMS",
    icon: "fa fa-info",
    subItem: [
      { sublabel: "Manage Content Page", link: "/manage-content-page" },
      { sublabel: "Manage Seo", link: "/manage-Seo" },
      { sublabel: "Manage Sliders", link: "/manage-sliders" },
      { sublabel: "Manage Testimonials", link: "/manage-testimonials" },
      { sublabel: "Manage Sponser", link: "/manage-Sponser" },
      { sublabel: "Manage Subscribe", link: "/manage-subscribe" },
      { sublabel: "Manage Subscription", link: "/manage-subscription" },

    ],
  },

  // {
  //   label: "Manage Customer / plan ",
  //   icon: "fa fa-bolt",
  //   subItem: [
  //     { sublabel: "Customer/Plan Data", link: "/manage-customer" },
  //     { sublabel: "Guest Customer", link: "/manage-guest-customer" },
  //     { sublabel: "Manage Order", link: "/manage-order" },
  //   ],
  // },

  // {
  //     label: "Manage Content Page",
  //     icon: "fa fa-bolt",
  //     isHasArrow: true,
  //     url: "/manage-content-page",
  // },
  // {
  //     label: "Manage Seo",
  //     icon: "fa fa-bolt",
  //     isHasArrow: true,
  //     url: "/manage-Seo",
  // },
  // {
  //     label: "Manage Sliders",
  //     icon: "fa fa-bolt",
  //     isHasArrow: true,
  //     url: "/manage-sliders",
  // },
  // {
  //     label: "Manage Testimonials",
  //     icon: "fa fa-bolt",
  //     isHasArrow: true,
  //     url: "/manage-testimonials",
  // },
  // {
  //     label: "Manage Sponser",
  //     icon: "fa fa-bolt",
  //     isHasArrow: true,
  //     url: "/manage-Sponser",
  // },
  // {
  //     label: "Manage Subscribe",
  //     icon: "fa fa-bolt",
  //     isHasArrow: true,
  //     url: "/manage-subscribe",
  // },
  {
    label: "Manage Subscription",
    icon: "fa fa-bolt",
    isHasArrow: true,
    url: "/manage-subscription",
  },
  {
    label: "Manage Tax Case",
    icon: "fa fa-bolt",
    isHasArrow: true,
    url: "/manage-tax-case",
  },
  {
    label: "Manage User",
    icon: "fa fa-bolt",
    isHasArrow: true,
    url: "/manage-user",
  },
  // {
  //     label: "Authentication",
  //     icon: "mdi mdi-account-circle-outline",
  //     subItem: [
  //         { sublabel: "Login", link: "/#" },
  //         { sublabel: "Register", link: "/#" },
  //         { sublabel: "Recover Password", link: "/#" },
  //         { sublabel: "Lock Screen", link: "/#" },
  //     ],
  // },
  {
    label: "ENQUIRY",
    isMainMenu: true,
  },
  {
    label: "Manage Enquiry",
    icon: "fa fa-bolt",
    subItem: [
      { sublabel: "Manage Customer Enquiry", link: "/manage-enquiry" },
      { sublabel: "Positive Enquiry", link: "/manage-positive-enquiry" },
      { sublabel: "Negative Enquiry", link: "/manage-negative-enquiry" },
    ],
  },

  // {
  //     label: "Components",
  //     isMainMenu: true,
  // },
  // {
  //     label: "UI Elements",
  //     icon: "mdi mdi-briefcase-variant-outline",
  //     subItem: [
  //         { sublabel: "Alerts", link: "/#" },
  //         { sublabel: "Badge", link: "/#" },
  //         { sublabel: "Breadcrumb", link: "/#" },
  //         { sublabel: "Buttons", link: "/#" },
  //         { sublabel: "Cards", link: "/#" },
  //         { sublabel: "Carousel", link: "/#" },
  //         { sublabel: "Dropdowns", link: "/#" },
  //         { sublabel: "Grid", link: "/#" },
  //         { sublabel: "Images", link: "/#" },
  //         { sublabel: "Lightbox", link: "/#" },
  //         { sublabel: "Modals", link: "/#" },
  //         { sublabel: "Offcanvas", link: "/#" },
  //         { sublabel: "Range Slider", link: "/#" },
  //         { sublabel: "Session Timeout", link: "/#" },
  //         { sublabel: "Pagination", link: "/#" },
  //         { sublabel: "Progress Bars", link: "/#" },
  //         { sublabel: "Placeholders", link: "/#" },
  //         { sublabel: "Tabs & Accordions", link: "/#" },
  //         { sublabel: "Typography", link: "/#" },
  //         { sublabel: "Toasts", link: "/#" },
  //         { sublabel: "Video", link: "/#" },
  //         { sublabel: "Popovers & Tooltips", link: "/#" },
  //         { sublabel: "Rating", link: "/#" },
  //     ],
  // },
  // {
  //     label: "Forms",
  //     icon: "ri-eraser-fill",
  //     issubMenubadge: true,
  //     bgcolor: "bg-danger",
  //     badgeValue: "8",
  //     subItem: [
  //         { sublabel: "Form Elements", link: "/#" },
  //         { sublabel: "Form Validation", link: "/#" },
  //         { sublabel: "Form Advanced Plugins", link: "/#" },
  //         { sublabel: "Form Editor", link: "/#" },
  //         { sublabel: "Form File Upload", link: "/#" },
  //         { sublabel: "Form X-editable", link: "/#" },
  //         { sublabel: "Form Wizard", link: "/#" },
  //         { sublabel: "Form Mask", link: "/#" },
  //     ],
  // },
  // {
  //     label: "Tables",
  //     icon: "ri-table-2",
  //     subItem: [
  //         { sublabel: "Basic Tables", link: "/#" },
  //         { sublabel: "Data Tables", link: "/#" },
  //         { sublabel: "Responsive Table", link: "/#" },
  //         { sublabel: "Editable Table", link: "/#" },
  //     ],
  // },
  // {
  //     label: "Charts",
  //     icon: "ri-bar-chart-line",
  //     subItem: [
  //         { sublabel: "Apex Charts", link: "/#" },
  //         { sublabel: "Chartjs Charts", link: "/#" },
  //         { sublabel: "Re Charts", link: "/#" },
  //         { sublabel: "Knob Charts", link: "/#" },
  //         { sublabel: "Sparkline Charts", link: "/#" },
  //     ],
  // },
  // {
  //     label: "Icons",
  //     icon: "ri-brush-line",
  //     subItem: [
  //         { sublabel: "Box Icons", link: "/#" },
  //         { sublabel: "Material Design", link: "/#" },
  //         { sublabel: "Dripicons", link: "/#" },
  //         { sublabel: "Font Awesome", link: "/#" },
  //     ],
  // },
  // {
  //     label: "Maps",
  //     icon: "ri-map-pin-line",
  //     subItem: [
  //         { sublabel: "Google Maps", link: "/#" },
  //         { sublabel: "Vector Maps", link: "/#" },
  //     ],
  // },
  // {
  //     label: "Multi Level",
  //     icon: "ri-share-line",
  //     subItem: [
  //         { sublabel: "Level 1.1", link: "/#" },
  //         {
  //             sublabel: "Level 1.2", link: "/#",
  //             subMenu: [
  //                 { title: "Level 2.1" },
  //                 { title: "Level 2.2" },
  //             ],
  //         },
  //     ],
  // },
];
export default SidebarData;
