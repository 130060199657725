import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link, useNavigate } from "react-router-dom";
import List from "list.js";
// Import Flatepicker
import Flatpickr from "react-flatpickr";
import { getRequest, postRequest } from "../../helpers/api_helper1";
import ToastSuccess from "../../components/Common/Toast";
import Loader from "../../components/Common/loader";

const Manage_Enquiry = () => {
  const [managePageData, setManagePageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(0);
  const [toast, settoast] = useState(false);
  const [toastContent, setToastContent] = useState("");

  const navigate = useNavigate();
  document.title =
    "Manage Content Page | Upzet - React Admin & Dashboard Template";

  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {}

  const [modal_delete, setmodal_delete] = useState(false);
  function tog_delete() {
    setmodal_delete(!modal_delete);
  }

  useEffect(() => {
    loadContent();
  }, []);

  const loadContent = async () => {
    setIsLoading(true);
    await getRequest({ url: "/admin/get-inquiry" })
      .then((response) => {
        console.log(response.data,"ddd");
        setManagePageData(response.data?.reverse());
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  //   const handleActive = async (status, id) => {
  //     let updatedStatus = status == 0 ? 1 : 0;
  //     let data = { status: updatedStatus, id: id };
  //     setIsLoading(true);
  //     try {
  //       const res = await postRequest({
  //         url: "/admin/manage-page-contant-status",
  //         data: data,
  //       });
  //       const response = JSON.parse(JSON.stringify(res));
  //       console.log(response);
  //       if (response?.status === 200) {
  //         setIsLoading(false);

  //         loadContent();
  //         // if (status == 1) {
  //         //   toast.success("Inactive successfully");
  //         // } else {
  //         //   toast.success("Active successfully");
  //         // }
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  const handleDelete = async (id) => {
    const result = window.confirm("Do you want to delete ?");

    if (result) {
      setIsLoading(true);
      let body = {
        id: id,
        delete_for: "manage_page_content",
      };
      await postRequest({
        url: "/admin/delete-tabuler-data",
        data: body,
      }).then((response) => {
        if (response.status === 200) {
          setToastContent("Deleted Success");
          settoast(true);
          //   toast.success("Delted successfully");
          setIsLoading(false);
          loadContent();
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Clear Law" breadcrumbItem="Manage Enquiry" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Page Listing</h4>
                </CardHeader>

                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto"> 
                      </Col>
                    </Row>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap"
                          id="customerTable"
                        >
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "50px" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                  />
                                </div>
                              </th>
                              <th className="sort" data-sort="page_name">
                                Name
                              </th>
                              <th className="sort" data-sort="url">
                                Phone
                              </th>
                              <th className="sort" data-sort="status">
                                Address
                              </th>
                              <th className="sort" data-sort="status">
                                Emails
                              </th>
                              <th className="sort" data-sort="action">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {managePageData?.map((item) => (
                              <>
                                <tr>
                                  <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value="option1"
                                      />
                                    </div>
                                  </th>
                                  <td
                                    className="id"
                                    style={{ display: "none" }}
                                  >
                                    <Link
                                      to="#"
                                      className="fw-medium link-primary"
                                    >
                                      #VZ2101
                                    </Link>
                                  </td>
                                  <td className="customer_name">
                                    {item?.name}
                                  </td>
                                  <td className="email">{item?.phone}</td>
                                  <td className="email">{item?.address}</td>
                                  <td className="email">{item?.email}</td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      {/* <div className="edit">
                                      <Link
                                        to={`/cms/managecontentpage/addcontentpage/${item.id}`}
                                        type="button"
                                        className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                      >
                                        <i className="fa fa-pencil"></i>
                                        edit
                                      </Link>
                                    </div> */}
                                      <div className="remove">
                                        <button
                                          className="btn btn-sm btn-danger btn-rounded"
                                          onClick={() => {
                                            handleDelete(item.id);
                                          }}
                                        >
                                          <i className="fa fa-trash "></i>
                                        </button>
                                        {/* <button
                                        type="button"
                                        className="btn btn-warning btn-sm btn-rounded  ms-2"
                                        onClick={() => {
                                        //   setStatus(item.status === 0 ? 1 : 0);
                                          handleActive(item.status, item.id);
                                        }}
                                      >
                                        <i className="fa fa-ban"></i>
                                      </button> */}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                        {toast && <ToastSuccess toastContent={toastContent} />}
                        <div className="noresult" style={{ display: "none" }}>
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            <p className="text-muted mb-0">
                              We've searched more than 150+ Orders We did not
                              find any orders for you search.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Manage_Enquiry;
