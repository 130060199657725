import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

const Logout = () => {
  
  useEffect(() => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("clearLaw_token");
    localStorage.clear();
  }, []);

  if (!(localStorage.removeItem("authUser"))) {
    return <Navigate to="/login" />;
  }

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);