import React from "react";
import UsePanel from "./UserPanel";

import Breadcrumbs from "../../components/Common/Breadcrumb";


import { Row, Container } from "reactstrap";


const Dashboard = () => {
  document.title = "Dashboard | Upzet - React Admin & Dashboard Template";

  return (
    <React.Fragment>
     <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Upzet" breadcrumbItem="Dashboard" />
          {/* User Panel Charts */}
          <UsePanel />

        </Container>
      </div>
    </React.Fragment>
  );
};
export default Dashboard;
