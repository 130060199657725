import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast } from "react-toastify";

import { getRequest, postRequest } from "../../helpers/api_helper1";

const AddSeoPage = () => {
  document.title = "Form Elements | Upzet - React Admin & Dashboard Template";
  const [formData, setFormData] = useState({
    page_Id: "",
    page_url: "",
    page_name: "",
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
  });

  const {
    page_url,
    page_name,
    meta_title,
    meta_keywords,
    meta_description,
    page_Id,
  } = formData;
  const [errors, setErrors] = useState({});
  const [pageError, setPageError] = useState("");
  const [pageurlError, setPageurlError] = useState("");
  const [pageNameError, setPageNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [metaTitleError, setMetaTitleError] = useState("");
  const [metaKeywordError, setMetaKeywordsError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [managePageData, setManagePageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const navigate = useNavigate();

  useEffect(() => {
    ListPage();
  }, []);

  const validateForm = () => {
    let errors = {};
    // check for empty values

    if (!formData.page_name) {
      errors.page_name = "Please Enter Tittle";
    }
    if (!formData.page_url) {
      errors.page_url = "Please enter this field";
    }
    if (!formData.meta_title) {
      errors.meta_title = "Please enter video link";
    }
    if (!formData.meta_description) {
      errors.meta_description = "Please enter description";
    }
    if (!formData.meta_keywords) {
      errors.meta_keywords = "Please enter description";
    }
    return errors;
  };

  const ListPage = async () => {
    getRequest({ url: "/admin/get-page-and-content" })
      .then((response) => {
        setManagePageData(response.data.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sampledata = [];
  managePageData?.forEach((item) => {
    sampledata?.push({ value: item.page_name, label: item.page_name });
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      let data = {
        page_name: page_name,
        page_url: page_url,
        meta_title: meta_title,
        meta_keywords: meta_keywords,
        meta_description: meta_keywords,
        page_id: page_Id,
      };
      postRequest({ url: "/admin/add-seo", data: data })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            navigate("/manage-seo");
            toast.success("Added successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          setPageError(error.response.data.result);
        });
    } else {
      setErrors(errors);
      console.log(errors);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    console.log(event.target, "mame");
    console.log(name, value, "mame");
    console.log(formData, "valueeeeeeee");
  };

  const selectStyles = (errorFieldName) => ({
    control: (provided) => ({
      ...provided,
      borderColor: errors[errorFieldName] ? "#dc3545" : provided.borderColor,
      boxShadow: errors[errorFieldName]
        ? "0 0 0 0.2rem rgba(220, 53, 69, 0.25)"
        : provided.boxShadow,
      minHeight: 22,
      height: 33,
    }),
  });

  const handleSelect = (fieldName, selectedOption) => {
    let get_Page_Id = managePageData.filter((id) => {
      if (selectedOption.value == id.page_name) return id;
    });
    console.log(get_Page_Id[0].id, "getpageid");
    let pageId = get_Page_Id[0].id;

    // setSelectedOption(selectedOption);
    console.log(selectedOption, "opton");
    setFormData({
      ...formData,
      [fieldName]: selectedOption.value,
      page_Id: pageId,
    });

    // setFormData({...formData, })
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <Container fluid={true}> */}
        <Breadcrumbs title="Manage Seo" breadcrumbItem="Add Seo" />
        <form onSubmit={handleSubmit}>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <div className="form-group row mb-4">
                <label for="taskname" className="col-form-label col-lg-2">
                  Page Name
                </label>
                <div className="col-lg-10">
                  <Select
                    className={`custom-select custom-select-sm ${
                      errors.page_name && "is-invalid"
                    }`}
                    options={sampledata}
                    value={sampledata.filter(
                      (option) => option.value == page_name
                    )}
                    styles={selectStyles("page_name")}
                    name="page_name"
                    onChange={(selectedOption) =>
                      handleSelect("page_name", selectedOption)
                    }
                  >
                    <option value="">Select an option</option>
                    {managePageData?.map((item) => (
                      <option value={item.id}>{item.page_name}</option>
                    ))}
                  </Select>
                  {errors.page_name && (
                    <div className="text-danger">{errors.page_name}</div>
                  )}
                </div>
              </div>

              <div className="form-group row mb-4">
                <label for="taskname" className="col-form-label col-lg-2">
                  Page url
                </label>
                <div className="col-lg-10">
                  <input
                    id="taskname"
                    name="page_url"
                    type="text"
                    value={page_url}
                    className={
                      errors.page_url
                        ? "form-control is-invalid"
                        : "form-control "                 
                    }
                    placeholder="Enter page url..."
                    onChange={handleInputChange}
                  />
                  {errors.page_url && (
                    <div className="text-danger">{errors.page_url}</div>
                  )}
                </div>
              </div>

              <div className="form-group row mb-4">
                <label for="taskname" className="col-form-label col-lg-2">
                  Meta title
                </label>
                <div className="col-lg-10">
                  <textarea
                    id="taskname"
                    name="meta_title"
                    type="text"
                    className={
                      errors.meta_title
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    placeholder="Enter meta title..."
                    onChange={handleInputChange}
                  />
                  {errors.meta_title && (
                    <div className="text-danger">{errors.meta_title}</div>
                  )}
                </div>
              </div>

              <div className="form-group row mb-4">
                <label for="taskname" className="col-form-label col-lg-2">
                  Meta keywords
                </label>
                <div className="col-lg-10">
                  <textarea
                    id="taskname"
                    name="meta_keywords"
                    type="text"
                    className={
                      errors.meta_keywords
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    placeholder="Enter meta keywords..."
                    onChange={handleInputChange}
                  />
                  {errors.meta_keywords && (
                    <div className="text-danger">{errors.title}</div>
                  )}
                </div>
              </div>
              <div className="form-group row mb-4">
                <label className="col-form-label col-lg-2">Description</label>
                <div className="col-lg-10">
                  <textarea
                    id="taskname"
                    name="meta_description"
                    type="text"
                    className={
                      errors.meta_description
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    placeholder="Enter meta keywords..."
                    onChange={handleInputChange}
                  />
                  {/* <Editor
                                  onEditorChange={handleEditorChangeDescription}
                                  init={{
                                    plugins:
                                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                    table_default_attributes: {
                                      border: "5",
                                    },
                                  }}
                                /> */}
                  {errors.meta_description && (
                    <div className="text-danger">{errors.meta_description}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-end">
            <div className="col-lg-10">
              {pageError && (
                <div className="alert alert-danger" role="alert">
                  {pageError}
                </div>
              )}
              <button type="submit" className="btn btn-primary">
                Add SEO Page
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddSeoPage;
