import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import Select from "react-select";
import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Editor } from "@tinymce/tinymce-react";
import { getRequest, postFormDataRequest, postRequest } from "../../helpers/api_helper1";

const AddSponser = () => {
  document.title = "Form Elements | Upzet - React Admin & Dashboard Template";
  const [formData, setFormData] = useState({
    sponsor_name: "",
    description: "",
    image: "",
  });

  const { sponsor_name, description, image } = formData;

  const [pageError, setPageError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [nameError, setNameError] = useState("");
  const [shortOrderError, setShortOrderError] = useState("");
  const [linkError, setLinkError] = useState("");
  const [servicesError, setServicesError] = useState("");
  const [error, setxError] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [imageView, setImageView] = useState();
  const [errors, setErrors] = useState({});

  const [managePageData, setManagePageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [show, setShow] = useState(true);

  const navigate = useNavigate();

  const { id } = useParams();

  let hasError = false;

  useEffect(() => {
    if (id) {
      ViewSponsor();
    }
  }, []);

  const ViewSponsor = async () => {
    const res = await postRequest({
      url: `/Admin/get-sponcer-based-on-id?id=${id}`,
    });
    if (res.data.status == 200) {
      setFormData({
        ...formData,
        sponsor_name: res.data.data.sponsor_name,
        image: res.data.data.image,
        description: res.data.data.description,
      });
      setImagePreview(res.data.data.image);
    }
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const validateForm = () => {
    let errors = {};
    // check for empty values

    if (!formData.sponsor_name) {
      errors.sponsor_name = "Please Enter Sponsor Name";
    }
    if (!formData.image) {
      errors.image = "Please Select Image";
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();

    const form = new FormData();
    form.append("sponsor_name", sponsor_name);
    form.append("image", imageView);
    form.append("description", description);

    if (Object.keys(errors).length === 0) {
      if (!id) {
        form.append("action", "add");
        postFormDataRequest({ url: `/admin/add-update-sponsor`, data: form })
          .then((response) => {
            console.log(response.status);
            if (response.status === 200) {
              navigate("/manage-sponser");
              toast.success("Added successfully");
            }
          })
          .catch((error) => {
            setPageError(error.response.data.result);
            console.log(error);
          });
      } else {
        form.append("id", id);
        form.append("action", "update");

        postFormDataRequest({ url: `/admin/add-update-sponsor`, data: form })
          .then((response) => {
            console.log(response.status);
            if (response.status === 200) {
              navigate("/manage-sponser");
                toast.success("Sponsor Added successfully");
            }
          })
          .catch((error) => {
            setPageError(error.response.result);
            console.log(error);
          });
      }
    } else {
      setErrors(errors);
      console.log(errors);
    } 
  };

  const handleInputChange = (event) => {
    console.log(formData, "form");
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (name === "image") {
      setShow(false);
      setImageView(event.target.files[0]);
    }
  };

  const handleImageChange = (e) => { 
    setShow(false);
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        {/* <Container fluid={true}> */}
        <Breadcrumbs title="Manage Subscribe" breadcrumbItem="Add Subscribe" />
        <form onSubmit={handleSubmit}>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <div className="form-group row mb-4">
                <label for="taskname" className="col-form-label col-lg-2">
                  Sponsor Name
                </label>
                <div className="col-lg-10">
                  <input
                    id="taskname"
                    name="sponsor_name"
                    type="text"
                    value={sponsor_name}
                    className={
                      errors.sponsor_name
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    placeholder="Enter Name"
                    onChange={handleInputChange}
                  />
                  {errors.sponsor_name && (
                    <div className="text-danger">{errors.sponsor_name}</div>
                  )}
                </div>
              </div>
              <div className="form-group row mb-4">
                <label className="col-form-label col-lg-2">Description</label>
                <div className="col-lg-10">
                  <Editor
                  apiKey='qx79nm58es7l62j5sl8c3l99q6ctf6dqprrp48mcuj9pbhsz'
                    onEditorChange={handleEditorChangeDescription}
                    value={description}
                    init={{
                      plugins:
                        "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                      table_default_attributes: {
                        border: "5",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="inner-repeater mb-4">
                <div
                  data-repeater-list="inner-group"
                  className="inner form-group mb-0 row"
                >
                  <label className="col-form-label col-lg-2">Image</label>
                  <div
                    data-repeater-item
                    className="inner col-lg-10 ms-md-auto"
                  >
                    <div className="mb-3 row align-items-center">
                      <div className="col-md-12">
                        <div className="mt-4 mt-md-0">
                          <input
                            className={
                              errors.sponsor_name
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              handleImageChange(e);
                              handleInputChange(e);
                            }}
                          />
                          {errors.image && (
                            <div className="text-danger">{errors.image}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mt-4 mt-20">
                          <div>
                            {imagePreview && (
                              <>
                                {show === true ? (
                                  <img
                                    src={`${image}`}
                                    alt="Preview"
                                    width="200"
                                    height="200"
                                  />
                                ) : (
                                  <img
                                    src={imagePreview}
                                    alt="Preview"
                                    width="200"
                                    height="200"
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-end">
            <div className="col-lg-10">
              {pageError && (
                <div className="alert alert-danger" role="alert">
                  {pageError}
                </div>
              )}
              <button type="submit" className="btn btn-primary">
                Add Sponsor
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddSponser;
