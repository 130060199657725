import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link, useNavigate } from "react-router-dom";
import List from "list.js";
// Import Flatepicker
import Flatpickr from "react-flatpickr";
import { getRequest, postRequest } from "../../helpers/api_helper1";
import Loader from "../../components/Common/loader";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Manage_User = () => {
  const [managePageData, setManagePageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageApi, setPageApi] = useState(1);
  const [pageSize, setPageSize] = useState();

  const [plans, setPlans] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");



  const [filterData, setFilterData] = useState({
    name: "",
    user_type: "",
    subscription_type: "",
    start_date: "",
    end_date: "",
    plan_type: "",
    plan_id: "",
  });
  const {
    name,
    user_type,
    subscription_type,
    start_date,
    end_date,
    plan_type,
    plan_id,
  } = filterData;

  const selectStyles = (errorFieldName) => ({
    control: (provided) => ({
      ...provided,
      borderColor: provided.borderColor,
      boxShadow: provided.boxShadow,
      minHeight: 22,
      height: 33,
    }),
  });

  const handleSelect = (fieldName, selectedOption) => {
    console.log(selectedOption, "opton");
    setFilterData({ ...filterData, [fieldName]: selectedOption.value });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const user_type_Options = [
    { value: "admin", label: "Admin" },
    { value: "customer", label: "Customer" },
  ];

  const in_Menu_Options = [
    { value: "monthly", label: "Monthly" },
    { value: "annualy", label: "Annualy" },
    { value: "Free Trial", label: "Free Trial" },
  ];

  const subscription_type_Options = [
    { value: "free_trial", label: "Free Trail" },
    { value: "paid", label: "Paid" },
  ];

  const ClearFilter = () => {
    setFilterData({
      name: "",
      user_type: "",
      subscription_type: "",
      start_date: "",
      end_date: "",
      plan_type: "",
      plan_id: "",
    });
  };


  const navigate = useNavigate();
  document.title =
    "Manage Content Page | Upzet - React Admin & Dashboard Template";

  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {}

  const [modal_delete, setmodal_delete] = useState(false);
  function tog_delete() {
    setmodal_delete(!modal_delete);
  }

  const handleActive = async (status, id) => {
    let updatedStatus = status == 0 ? 1 : 0;
    let data = { status: updatedStatus, id: id };
    setIsLoading(true);
    try {
      const res = await postRequest({
        url: "/admin/update-testimonial-status",
        data: data,
      });
      const response = JSON.parse(JSON.stringify(res));
      console.log(response);
      if (response?.status === 200) {
        setIsLoading(false);

        loadContent();
        if (status == 1) {
          toast.success("Inactive successfully");
        } else {
          toast.success("Active successfully");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (id) => {
    const result = window.confirm("Do you want to delete ?");

    if (result) {
      setIsLoading(true);
      let body = {
        id: id,
        delete_for: "users",
      };
      await postRequest({
        url: "/admin/delete-tabuler-data",
        data: body,
      }).then((response) => {
        if (response.status === 200) {
          toast.success("Delted successfully");
          setIsLoading(false);
          loadContent();
        }
      });
    }
  };

  useEffect(() => {
    // setToken(localStorage.getItem("cms_token"));
    loadContent();
  }, [pageApi]);

  const loadContent = async () => {
    setIsLoading(true);
    let param = {
      page: pageApi,
      limit: "10",
    };
    await getRequest({ url: "/admin/get-user-list", params: param })
      .then((response) => {
        console.log(response.data);
        setManagePageData(response.data?.reverse());
        const totalPageCount = Math.ceil(response?.total_user / 10);
        setPageSize(totalPageCount);
        console.log(totalPageCount,"totalpage")
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const planOption = [];
  plans?.forEach((item) => {
    planOption.push({ value: item.id, label: item.name });
  });


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Clear Law" breadcrumbItem="Manage User" />

          {/* //Table section */}

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">User Listing</h4>
                </CardHeader>

                <div className="card">
                  <div
                    className="card-body rounded-3"
                    style={{ backgroundColor: "#e57c2ef2" }}
                  >
                    <div className="row">
                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            Plan
                          </label>
                          <Select
                            className={`custom-select custom-select-sm`}
                            options={planOption}
                            value={planOption.filter(
                              (option) => option.value == plan_id
                            )}
                            styles={selectStyles("plan_id")}
                            name="plan_id"
                            onChange={(selectedOption) =>
                              handleSelect("plan_id", selectedOption)
                            }
                          >
                            <option value="">Select an option</option>
                            {planOption?.map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            User Type
                          </label>
                          <Select
                            className={`custom-select custom-select-sm`}
                            options={user_type_Options}
                            value={user_type_Options.filter(
                              (option) => option.value == user_type
                            )}
                            placeholder="Select Plan"
                            styles={selectStyles("user_type")}
                            name="user_type"
                            onChange={(selectedOption) =>
                              handleSelect("user_type", selectedOption)
                            }
                          >
                            <option value="">Select an option</option>
                            {user_type_Options?.map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            Subscription Type
                          </label>
                          <Select
                            className={`custom-select custom-select-sm`}
                            options={subscription_type_Options}
                            value={subscription_type_Options.filter(
                              (option) => option.value == subscription_type
                            )}
                            placeholder="Select Plan"
                            styles={selectStyles("subscription_type")}
                            name="subscription_type"
                            onChange={(selectedOption) =>
                              handleSelect("subscription_type", selectedOption)
                            }
                          >
                            <option value="">Select an option</option>
                            {subscription_type_Options?.map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            Plan Type
                          </label>
                          <Select
                            className={`custom-select custom-select-sm`}
                            options={in_Menu_Options}
                            value={in_Menu_Options.filter(
                              (option) => option.value == plan_type
                            )}
                            placeholder="Select Plan"
                            styles={selectStyles("plan_type")}
                            name="plan_type"
                            onChange={(selectedOption) =>
                              handleSelect("plan_type", selectedOption)
                            }
                          >
                            <option value="">Select an option</option>
                            {in_Menu_Options?.map((item) => (
                              <option value={item.value}>{item.label}</option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="col-3 mt-2">
                        <div className="form-group row mb-4 px-3">
                          <label
                            for="taskname"
                            className="col-form-label text-white col-lg-8"
                          >
                            Name
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Enter Name"
                            value={name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-3 d-flex align-items-center pt-3" >
                        {/* <span  className="me-2">Start Date</span> */}
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          dateFormat="dd-MM-yyyy"
                          wrapperClassName="datePicker "
                          placeholderText="Start Date"
                          // startDate={new Date()}
                          // endDate={endDate}
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center pt-3">
                        {/* <span className="me-2">End Date</span> */}
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          dateFormat="dd-MM-yyyy"
                          wrapperClassName="datePicker w-100"
                          placeholderText="End Date"

                          // endDate={endDate}
                          // minDate={startDate}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between">
                      <div className="col-3 clear-filter d-flex text-start">
                        <button onClick={() => ClearFilter()}>
                          Clear Filter
                        </button>
                      </div>
                      <div className="col-3 date-submit">
                        <button
                          style={{ height: "40px" }}
                          onClick={() => loadContent()}
                        >
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          <Button
                            color="success"
                            className="add-btn"
                            onClick={() => navigate("/add-user")}
                            id="create-btn"
                          >
                            <i className="fa fa-plus  me-1"></i> Add
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap"
                          id="customerTable"
                        >
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "50px" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                  />
                                </div>
                              </th>
                              <th className="sort" data-sort="page_name">
                                Name
                              </th>
                              <th className="sort" data-sort="url">
                                Email
                              </th>
                              <th className="sort" data-sort="url">
                                Mobile
                              </th>
                              <th className="sort" data-sort="status">
                                Role
                              </th>
                              <th className="sort" data-sort="action">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {managePageData?.map((item) => (
                              <>
                                <tr>
                                  <th scope="row">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value="option1"
                                      />
                                    </div>
                                  </th>
                                  <td
                                    className="id"
                                    style={{ display: "none" }}
                                  >
                                    <Link
                                      to="#"
                                      className="fw-medium link-primary"
                                    >
                                      #VZ2101
                                    </Link>
                                  </td>
                                  <td className="customer_name">
                                    {item?.name}
                                  </td>
                                  <td className="email">{item?.email}</td>
                                  <td className="email">
                                    {item?.mobile ? item?.mobile : "Nill"}
                                  </td>
                                  <td className="status">
                                    {item?.user_type == "admin" ? (
                                      <span className="badge badge-pill badge-soft-dark text-uppercase">
                                        Admin
                                      </span>
                                    ) : item?.user_type == "Master User" ? (
                                      <span className="badge badge-pill badge-soft-primary text-uppercase">
                                        Master User
                                      </span>
                                    ) : item?.user_type == "CRM User" ? (
                                      <span className="badge badge-pill badge-soft-warning text-uppercase">
                                        CRM User
                                      </span>
                                    ) : item?.user_type == "Gust User" ? 
                                     ( <span className="badge badge-pill badge-soft-warning text-uppercase">
                                        Guest User
                                      </span>
                                    ):null}
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                    <div className="edit">
                                        <Link
                                          to={`/manage-user/view-user/${item.id}`}
                                          type="button"
                                          className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                        >
                                          <i className="fa fa-eye"></i>
                                          
                                        </Link>
                                      </div>
                                      {/* <div className="edit">
                                        <Link
                                          to={`/edit-testimonial/${item.id}`}
                                          type="button"
                                          className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                        >
                                          <i className="fa fa-pencil"></i>
                                          
                                        </Link>
                                      </div> */}
                                      <div className="remove">
                                        <button
                                          className="btn btn-sm btn-danger btn-rounded"
                                          onClick={() => {
                                            handleDelete(item.id);
                                          }}
                                        >
                                          {" "}
                                          <i className="fa fa-trash "></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-warning btn-sm btn-rounded  ms-2"
                                          onClick={() => {
                                            handleActive(item.status, item.id);
                                          }}
                                        >
                                          <i className="fa fa-ban"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                        <div className="noresult" style={{ display: "none" }}>
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            <p className="text-muted mb-0">
                              We've searched more than 150+ Orders We did not
                              find any orders for you search.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="d-flex justify-content-center">
                      <Pagination
                        count={pageSize}
                        page={pageApi}
                        onChange={(e, value) => {
                          console.log(value, "vslue");
                          setPageApi(value);
                        }}
                        color="primary"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Add Modal */}
          <Modal
            isOpen={modal_list}
            toggle={() => {
              tog_list();
            }}
            centered
          >
            <ModalHeader
              className="bg-light p-3"
              id="exampleModalLabel"
              toggle={() => {
                tog_list();
              }}
            >
              {" "}
              Add Customer{" "}
            </ModalHeader>
            <form className="tablelist-form">
              <ModalBody>
                <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                  <label htmlFor="id-field" className="form-label">
                    ID
                  </label>
                  <input
                    type="text"
                    id="id-field"
                    className="form-control"
                    placeholder="ID"
                    readOnly
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="customername-field" className="form-label">
                    Customer Name
                  </label>
                  <input
                    type="text"
                    id="customername-field"
                    className="form-control"
                    placeholder="Enter Name"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="email-field" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email-field"
                    className="form-control"
                    placeholder="Enter Email"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="phone-field" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    id="phone-field"
                    className="form-control"
                    placeholder="Enter Phone no."
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="date-field" className="form-label">
                    Joining Date
                  </label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      dateFormat: "d M, Y",
                    }}
                    placeholder="Select Date"
                  />
                </div>

                <div>
                  <label htmlFor="status-field" className="form-label">
                    Status
                  </label>
                  <select
                    className="form-control"
                    data-trigger
                    name="status-field"
                    id="status-field"
                  >
                    <option value="">Status</option>
                    <option value="Active">Active</option>
                    <option value="Block">Block</option>
                  </select>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => setmodal_list(false)}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    id="add-btn"
                  >
                    Add Customer
                  </button>
                  {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                </div>
              </ModalFooter>
            </form>
          </Modal>

          {/* Remove Modal */}
          <Modal
            isOpen={modal_delete}
            toggle={() => {
              tog_delete();
            }}
            className="modal fade zoomIn"
            id="deleteRecordModal"
            centered
          >
            <div className="modal-header">
              <Button
                type="button"
                onClick={() => setmodal_delete(false)}
                className="btn-close"
                aria-label="Close"
              >
                {" "}
              </Button>
            </div>
            <ModalBody>
              <div className="mt-2 text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/gsqxdxog.json"
                  trigger="loop"
                  colors="primary:#f7b84b,secondary:#f06548"
                  style={{ width: "100px", height: "100px" }}
                ></lord-icon>
                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                  <h4>Are you Sure ?</h4>
                  <p className="text-muted mx-4 mb-0">
                    Are you Sure You want to Remove this Record ?
                  </p>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button
                  type="button"
                  className="btn w-sm btn-light"
                  onClick={() => setmodal_delete(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn w-sm btn-danger "
                  id="delete-record"
                >
                  Yes, Delete It!
                </button>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Manage_User;
