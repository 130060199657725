import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Editor } from "@tinymce/tinymce-react";
import {
  getRequest,
  postFormDataRequest,
  postRequest,
} from "../../helpers/api_helper1";
import { toast } from "react-toastify";

const AddUser = () => {
  document.title = "Form Elements | Upzet - React Admin & Dashboard Template";
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
    user_type: "",
  });

  const { name, email, password, mobile, user_type } = formData;

  const [errors, setErrors] = useState({});

  const [pageError, setPageError] = useState("");
  // const [image, setImage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [ratingError, setRatingError] = useState("");
  const [cityAndCountryError, setCityAndCountry] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [managePageData, setManagePageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("cms_token"));

  const navigate = useNavigate();
  const { id } = useParams();

  const validateForm = () => {
    let errors = {};
    // check for empty values

    if (!formData.password) {
      errors.password = "Please Enter Password";
    }
    if (!formData.name) {
      errors.name = "Please enter Name";
    }
    if (!formData.email) {
      errors.email = "Please Upload Email";
    }
    if (!formData.mobile) {
      errors.mobile = "Please enter Mobile";
    }
    if (!formData.user_type) {
      errors.user_type = "Please enter User Type";
    }
    return errors;
  };

  useEffect(() => {
    if (id) {
      fetchPrefilled(id);
    }
  }, []);

  const fetchPrefilled = async (id) => {
    let param = { id: id };
    try {
      // setIsLoading(true);
      const enquiryResponse = getRequest({
        url: `/admin/get-testimonial-id-basis`,
        params: param,
      }).then((res) => {
        const enquiryRes = JSON.parse(JSON.stringify(enquiryResponse));
        console.log(res, "res");
        if (res.status == 200) {
          // setIsLoading(false);
          setFormData({
            ...formData,
            url: res.data.url,
            name: res.data.name,
            rating: res.data.rating,
            description: res.data.description,
            city_country: res.data.city_country,
            image: res.data.image,
          });

          setImagePreview(res.data.image);
        }
      });
    } catch (error) {}
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();

    const form = new FormData();
    form.append("name", name);
    form.append("email", email);
    form.append("password", password);
    form.append("mobile", mobile);
    form.append("user_type", user_type);

    if (Object.keys(errors).length === 0) {
      if (!id) {
        form.append("action", "add");
        postFormDataRequest({ url: `/admin/add-admin`, data: form })
          .then((response) => {
            console.log(response.status, "status");
            if (response.status === 200) {
              navigate("/manage-user");
              toast.success("Delete successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.data.result);
          });
      } else {
        form.append("action", "update");
        form.append("id", id);

        postFormDataRequest({
          url: `/admin/add-update-testimonial`,
          data: form,
        })
          .then((response) => {
            console.log(response.status, "status");
            if (response.status === 200) {
              navigate("/manage-testimonials");
              toast.success("Delete successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.data.result);
          });
      }
    } else {
      setErrors(errors);
      console.log(errors);
    }
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });

    if (name === "image") {
      setFormData({ ...formData, [name]: event.target.files[0] });
      // setImage(event.target.files[0]);
    }
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };

  const selectStyles = (errorFieldName) => ({
    control: (provided) => ({
      ...provided,
      borderColor: errors[errorFieldName] ? "#dc3545" : provided.borderColor,
      boxShadow: errors[errorFieldName]
        ? "0 0 0 0.2rem rgba(220, 53, 69, 0.25)"
        : provided.boxShadow,
      minHeight: 22,
      height: 33,
    }),
  });

  const handleSelect = (fieldName, selectedOption) => {
    // setSelectedOption(selectedOption);
    console.log(selectedOption, "opton");
    setFormData({ ...formData, [fieldName]: selectedOption.value });
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const in_Menu_Options = [
    { value: 0, label: "Admin" },
    { value: 1, label: "Master User" },
    { value: 2, label: "CRM User" },
  ];


  return (
    <React.Fragment>
      <div className="page-content">
        {/* <Container fluid={true}> */}
        <Breadcrumbs
          title="Manage Testimonials"
          breadcrumbItem="Add User"
        />
        <form onSubmit={handleSubmit}>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <div className="form-group row mb-4">
                <label for="taskname" className="col-form-label col-lg-2">
                  Name
                </label>
                <div className="col-lg-10">
                  <input
                    id="taskname"
                    name="name"
                    type="text"
                    value={name}
                    className={
                      errors.name ? "form-control is-invalid" : "form-control "
                    }
                    placeholder="Enter Name..."
                    onChange={handleInputChange}
                  />
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                </div>
              </div>

              <div className="form-group row mb-4">
                <label for="taskname" className="col-form-label col-lg-2">
                  Email
                </label>
                <div className="col-lg-10">
                  <input
                    id="taskname"
                    name="email"
                    type="text"
                    value={email}
                    className={
                      errors.email ? "form-control is-invalid" : "form-control "
                    }
                    placeholder="Enter Email..."
                    onChange={handleInputChange}
                  />
                  {errors.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                </div>
              </div>

              <div className="form-group row mb-4">
                <label for="taskname" className="col-form-label col-lg-2">
                  Mobile
                </label>
                <div className="col-lg-10">
                  <input
                    id="taskname"
                    name="mobile"
                    type="text"
                    value={mobile}
                    className={
                      errors.mobile
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    placeholder="Enter Mobile..."
                    onChange={handleInputChange}
                  />
                  {errors.mobile && (
                    <div className="text-danger">{errors.mobile}</div>
                  )}
                </div>
              </div>

              <div className="form-group row mb-4">
                <label for="taskname" className="col-form-label col-lg-2">
                  Password
                </label>
                <div className="col-lg-10">
                  <input
                    id="taskname"
                    name="password"
                    type="text"
                    value={password}
                    className={
                      errors.password
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    placeholder="Enter Password..."
                    onChange={handleInputChange}
                  />
                  {errors.password && (
                    <div className="text-danger">{errors.password}</div>
                  )}
                </div>
              </div>

              <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
                User Type
              </label>
              <div className="col-lg-10">
                <Select
                  className={`custom-select custom-select-sm`}
                  options={in_Menu_Options}
                  value={in_Menu_Options.filter(
                    (option) => option.value == user_type
                  )}
                  styles={selectStyles("user_type")}
                  name="user_type"
                  onChange={(selectedOption) =>
                    handleSelect("user_type", selectedOption)
                  }
                >
                  <option value="">Select an option</option>
                  {in_Menu_Options?.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </Select>
              </div>
            </div>

            </div>
          </div>

          <div className="row justify-content-end">
            <div className="col-lg-10">
              {pageError && (
                <div className="alert alert-danger" role="alert">
                  {pageError}
                </div>
              )}
              <button type="submit" className="btn btn-primary">
                Add User
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddUser;
