import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config";
import axios from "axios";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../helpers/api_helper1";
import Select from "react-select";
import { Button, Col, Row } from "reactstrap";

const EditPlan = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    monthly_price: "",
    annual_price: "",
    offer: "",
    api_calls: "",
    duration: "",
    plan_type: "",
    judgement_download: "",
  });
  const {
    name,
    description,
    monthly_price,
    annual_price,
    offer,
    api_calls,
    duration,
    judgement_download,
    plan_type,
  } = formData;

  const [tradeMarkList, setTradeMarkList] = useState();
  const [tradeMarkInfo, setTradeMarkInfo] = useState({
    trademark_info: [],
    status: [],
    filling_mode: [],
    tm_category: [],
    search_for: [],
  });
  const [statusArray, setStatusArray] = useState([]);
  const [trademarkArray, setTrademarkArray] = useState([]);
  const [fillingArray, setfillingArray] = useState([]);
  const [tmArray, settmArray] = useState([]);
  const [classArray, setClassArray] = useState([]);

  const [date, setDate] = useState();
  const [errors, setErrors] = useState({});

  const [pageError, setPageError] = useState("");

  const [token, setToken] = useState(localStorage.getItem("cms_token"));

  const navigate = useNavigate();
  const { id } = useParams();

  let hasError = false;

  useEffect(() => {
    setToken(localStorage.getItem("cms_token"));
    getRequest({ url: "/admin/add-subscription-plan-view" })
      .then((res) => {
        console.log(res.data, "resonu");
        getDynamicList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getRequest({
      url: "/admin/get-subscription-plan-cms-details",
      params: { id: id },
    })
      .then((res) => {
        console.log(res.data, "resonace");
        Checked_Array(res.data.offared_module);
        setFormData({
          ...formData,
          plan_id: parseInt(id),
          name: res.data.name,
          description: res.data.description,
          monthly_price: res.data.monthly_price,
          annual_price: res.data.annual_price,
          offer: res.data.offer,
          api_calls: res.data.api_calls,
          duration: res.data.duration,
          judgement_download: res.data.judgement_download,
          // page_image: enquiryRes.data.page_image,
        });
        // setTradeMarkInfo({
        //     trademark_info: res.data,
        //     status: res.data,
        //     filling_mode: res.data,
        //     tm_category: res.data,
        // })
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const Checked_Array = (str) => {
    const parts = str.split('#');

    // Map through the parts and remove the prefixes
    const result = parts.map((part, index) => {
      // For the first part, also remove the 'Search By@' prefix
      if (index === 0) {
        return part.replace('Search By@', '');
      }
      return part;
    });
    console.log(result,"checked_arr")
  setTrademarkArray(result);

};

  const selectStyles = (errorFieldName) => ({
    control: (provided) => ({
      ...provided,
      borderColor: provided.borderColor,
      boxShadow: provided.boxShadow,
      minHeight: 22,
      height: 33,
    }),
  });

  const handleSelect = (fieldName, selectedOption) => {
    console.log(selectedOption, "opton");
    setFormData({ ...formData, [fieldName]: selectedOption.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData, "form");
    let form = {
      search_for: `Search By@${trademarkArray?.toString().replace(/\,/g, "#")}`,
      action: "update",
      id:id,
      ...formData,
    };

    postRequest({
      url: "crm/add-subscription-plan",
      data: form,
    })
      .then((response) => {
        console.log(response, "donom");
        if (response.status === 200) {
          navigate("/manage-subscription");
          toast.success("Plan added successfully");
        }
      })
      .catch((error) => {
        console.log(error);
        setPageError(error.response.data.result);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const getDynamicList = (data) => {
    console.log(data, "dunamic");

    setTradeMarkList([]);

    data?.map((item, i) => {
      setTradeMarkList((prev) => [...prev, item]);
    });
  };

  console.log(tradeMarkList, "statusList");

  const handleClassChange = async (e) => {
    const { value, checked } = e?.target;
    const { search_for } = tradeMarkInfo;

    if (checked) {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        search_for: [...search_for, e.target.value],
      });
      setClassArray([...classArray, value]);
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        search_for: search_for?.filter((e) => e !== value),
      });
      setClassArray((items) => items.filter((e) => e !== value));
    }
  };

  const handleTradeMarkChange = async (e) => {
    const { value, checked } = e?.target;
    const { trademark_info } = tradeMarkInfo;

    if (checked) {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        trademark_info: [...trademark_info, e.target.value],
      });
      setTrademarkArray([...trademarkArray, value]);
    } else {
      setTradeMarkInfo({
        ...tradeMarkInfo,
        trademark_info: trademark_info?.filter((e) => e !== value),
      });
      setTrademarkArray((items) => items.filter((e) => e !== value));
    }
  };

  const onDateChange = (e) => {
    console.log(e.target.value, "etarget");
    if (e.target.name == "start_date") {
      setDate({
        ...date,
        start_date: e.target.value,
      });
    } else {
      setDate({
        ...date,
        end_date: e.target.value,
      });
    }
  };

  const sampledata = [
    { value: "paid", label: "Paid" },
    { value: "FreeTrial", label: "Free Trial" },
  ];

  return (
    <>
      <div className="">
        <div className="">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Edit Plan </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">CMS</a>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to={"/cms/manageenquiry"}>Manage Plan</Link>
                      </li>

                      <li className="breadcrumb-item active">Edit Plan</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mt-5">
                    <div className="card-body">
                      <Row className="g-4 mb-3 justify-content-end">
                        <Col className="col-sm-auto">
                          <div className="d-flex gap-1">
                            <Button
                              color="success"
                              className="add-btn"
                              onClick={() => window.history.back()}
                              id="create-btn"
                            >
                              <i className="fa fa-arrow-left  me-1"></i> Back
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <h4 className="card-title mb-4">Edit Plan</h4>
                      <form onSubmit={handleSubmit}>
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer row">
                            <div class="col-md-6 col-xl-9">
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Name
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="name"
                                    type="text"
                                    value={name}
                                    className={
                                      errors.name
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter name..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.name && (
                                    <div className="text-danger">
                                      {errors.name}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Description
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="description"
                                    type="text"
                                    value={description}
                                    className={
                                      errors.description
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter Description..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.description && (
                                    <div className="text-danger">
                                      {errors.description}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Monthly Price
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="monthly_price"
                                    type="text"
                                    value={monthly_price}
                                    className={
                                      errors.monthly_price
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter monthly price..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.monthly_price && (
                                    <div className="text-danger">
                                      {errors.monthly_price}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Annual Price
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="annual_price"
                                    type="text"
                                    value={annual_price}
                                    className={
                                      errors.annual_price
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter Annual Price..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.annual_price && (
                                    <div className="text-danger">
                                      {errors.annual_price}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Duration
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="duration"
                                    type="text"
                                    value={duration}
                                    className={
                                      errors.duration
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter Duration..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.duration && (
                                    <div className="text-danger">
                                      {errors.duration}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Offer
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="offer"
                                    type="text"
                                    value={offer}
                                    className={
                                      errors.offer
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter Offer..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.offer && (
                                    <div className="text-danger">
                                      {errors.offer}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  API Calls
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="api_calls"
                                    type="text"
                                    value={api_calls}
                                    className={
                                      errors.api_calls
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter ..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.api_calls && (
                                    <div className="text-danger">
                                      {errors.api_calls}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Judgement Download Limit
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    id="taskname"
                                    name="judgement_download"
                                    type="text"
                                    value={judgement_download}
                                    className={
                                      errors.judgement_download
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                    placeholder="Enter ..."
                                    onChange={handleInputChange}
                                  />
                                  {errors.judgement_download && (
                                    <div className="text-danger">
                                      {errors.judgement_download}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row mb-4">
                                <label
                                  for="taskname"
                                  className="col-form-label col-lg-2"
                                >
                                  Plan Type
                                </label>
                                <div className="col-lg-10">
                                  <Select
                                    className={`custom-select custom-select-sm ${
                                      errors.plan_type && "is-invalid"
                                    }`}
                                    options={sampledata}
                                    value={sampledata.filter(
                                      (option) => option.value == plan_type
                                    )}
                                    styles={selectStyles("plan_type")}
                                    name="plan_type"
                                    onChange={(selectedOption) =>
                                      handleSelect("plan_type", selectedOption)
                                    }
                                  >
                                    <option value="">Select an option</option>
                                    {sampledata?.map((item) => (
                                      <option value={item.value}>
                                        {item.label}
                                      </option>
                                    ))}
                                  </Select>
                                  {errors.plan_type && (
                                    <div className="text-danger">
                                      {errors.plan_type}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-xl-3 col-lg-3">
                              <div class="card">
                                <div class="card-header bg-dark text-white">
                                  <p class="mb-0">Filter by Search For</p>
                                </div>
                                <div class="card-body">
                                  {tradeMarkList?.map((item, index) => {
                                    console.log(item, "item33");
                                    return (
                                      <>
                                        <div class="form-check">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            name="search_for"
                                            value={item?.filter_by}
                                            id={`trademark-checkbox-${index}`}
                                            onChange={handleTradeMarkChange}
                                            //   disabled={!item?.status}
                                            checked={trademarkArray.includes(
                                              item?.filter_by
                                            )}
                                          />
                                          <label
                                            class="form-check-label"
                                            for="flexCheckDefault"
                                          >
                                            {item?.filter_by}
                                          </label>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-end">
                          <div className="col-lg-10 mb-4">
                            {pageError && (
                              <div className="alert alert-danger" role="alert">
                                {pageError}
                              </div>
                            )}
                            <button type="submit" className="btn btn-primary">
                              Update Plan
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPlan;
