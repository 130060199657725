import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Editor } from "@tinymce/tinymce-react";
import {
  getRequest,
  postFormDataRequest,
  postRequest,
} from "../../helpers/api_helper1";
import { toast } from "react-toastify";

const AddTestimonial = () => {
  document.title = "Form Elements | Upzet - React Admin & Dashboard Template";
  const [formData, setFormData] = useState({
    url: "",
    name: "",
    rating: "",
    description: "",
    city_country: "",
    image: "",
  });

  const { url, name, rating, metakeywords, description, city_country, image } =
    formData;

  const [errors, setErrors] = useState({});

  const [pageError, setPageError] = useState("");
  // const [image, setImage] = useState("");
  const [pageurlError, setPageurlError] = useState("");
  const [pageNameError, setPageNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [ratingError, setRatingError] = useState("");
  const [cityAndCountryError, setCityAndCountry] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [managePageData, setManagePageData] = useState();
  const [token, setToken] = useState(localStorage.getItem("cms_token"));

  const navigate = useNavigate();
  const { id } = useParams();

  const validateForm = () => {
    let errors = {};
    // check for empty values

    if (!formData.url) {
      errors.url = "Please Enter Url";
    }
    if (!formData.name) {
      errors.name = "Please enter Name";
    }
    if (!formData.image) {
      errors.image = "Please Upload Image";
    }
    if (!formData.rating) {
      errors.rating = "Please enter Rating";
    }
    return errors;
  };

  useEffect(() => {
    if (id) {
      fetchPrefilled(id);
    }
  }, []);

  const fetchPrefilled = async (id) => {
    let param = {id:id}
    try {
      // setIsLoading(true);
      const enquiryResponse =  getRequest({ url: `/admin/get-testimonial-id-basis`, params:param})
      .then((res)=>{
        const enquiryRes = JSON.parse(JSON.stringify(enquiryResponse));
        console.log(res, "res");
        if (res.status == 200) {
          // setIsLoading(false);
          setFormData({
            ...formData,
            url: res.data.url,
            name: res.data.name,
            rating: res.data.rating,
            description: res.data.description,
            city_country: res.data.city_country,
            image: res.data.image,
          });
  
          setImagePreview(res.data.image);
        }
      })

     
    } catch (error) {}
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();

    console.log(image, "image");
    const form = new FormData();
    form.append("name", name);
    form.append("city_country", city_country);
    form.append("url", url);
    form.append("rating", rating);
    form.append("description", description);
    form.append("image", image);

    if (Object.keys(errors).length === 0) {
      if (!id) {
        form.append("action", "add");
        postFormDataRequest({ url: `/admin/add-update-testimonial`, data:form })
          .then((response) => {
            console.log(response.status, "status");
            if (response.status === 200) {
              navigate("/manage-testimonials");
                toast.success("Delete successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.data.result);
          });
      } else {
        form.append("action", "update");
        form.append("id", id);

        postFormDataRequest({ url: `/admin/add-update-testimonial`, data:form })
          .then((response) => {
            console.log(response.status, "status");
            if (response.status === 200) {
              navigate("/manage-testimonials");
                toast.success("Delete successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.data.result);
          });
      }
    } else {
      setErrors(errors);
      console.log(errors);
    }
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });

    if (name === "image") {
      setFormData({ ...formData, [name]: event.target.files[0] });
      // setImage(event.target.files[0]);
    }
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        {/* <Container fluid={true}> */}
        <Breadcrumbs
          title="Manage Testimonials"
          breadcrumbItem="Add Testimonial"
        />
        <form onSubmit={handleSubmit}>
          <div data-repeater-list="outer-group" className="outer">
            <div data-repeater-item className="outer">
              <div className="form-group row mb-4">
                <label for="taskname" className="col-form-label col-lg-2">
                  Name
                </label>
                <div className="col-lg-10">
                  <input
                    id="taskname"
                    name="name"
                    type="text"
                    value={name}
                    className={
                      errors.name ? "form-control is-invalid" : "form-control "
                    }
                    placeholder="Enter pagename..."
                    onChange={handleInputChange}
                  />
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                </div>
              </div>

              <div className="form-group row mb-4">
                <label for="taskname" className="col-form-label col-lg-2">
                  City and country
                </label>
                <div className="col-lg-10">
                  <input
                    id="taskname"
                    name="city_country"
                    type="text"
                    value={city_country}
                    className={
                      cityAndCountryError
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    placeholder="Enter city and country url..."
                    onChange={handleInputChange}
                  />
                  {errors.city_country && (
                    <div className="text-danger">{errors.city_country}</div>
                  )}
                </div>
              </div>

              <div className="form-group row mb-4">
                <label for="taskname" className="col-form-label col-lg-2">
                  URL
                </label>
                <div className="col-lg-10">
                  <input
                    id="taskname"
                    name="url"
                    type="text"
                    value={url}
                    className={
                      errors.url ? "form-control is-invalid" : "form-control "
                    }
                    placeholder="Enter page url..."
                    onChange={handleInputChange}
                  />
                  {errors.url && (
                    <div className="text-danger">{errors.url}</div>
                  )}
                </div>
              </div>

              <div className="form-group row mb-4">
                <label for="taskname" className="col-form-label col-lg-2">
                  Ratings
                </label>
                <div className="col-lg-10">
                  <input
                    id="taskname"
                    name="rating"
                    type="number"
                    value={rating}
                    className={
                      errors.rating
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    placeholder="Enter rating..."
                    onChange={handleInputChange}
                  />
                  {errors.rating && (
                    <div className="text-danger">{errors.rating}</div>
                  )}
                </div>
              </div>

              <div className="form-group row mb-4">
                <label className="col-form-label col-lg-2">Description</label>
                <div className="col-lg-10">
                  <Editor
                  apiKey='qx79nm58es7l62j5sl8c3l99q6ctf6dqprrp48mcuj9pbhsz'
                    onEditorChange={handleEditorChangeDescription}
                    value={description}
                    init={{
                      plugins:
                        "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                      table_default_attributes: {
                        border: "5",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="inner-repeater mb-4">
                <div
                  data-repeater-list="inner-group"
                  className="inner form-group mb-0 row"
                >
                  <label className="col-form-label col-lg-2">Image</label>
                  <div
                    data-repeater-item
                    className="inner col-lg-10 ms-md-auto"
                  >
                    <div className="mb-3 row align-items-center">
                      <div className="col-md-12">
                        <div className="mt-4 mt-md-0">
                          <input
                            className={
                              errors.image
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              handleImageChange(e);
                              handleInputChange(e);
                            }}
                          />
                          {errors.image && (
                            <div className="text-danger">{errors.image}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mt-4 mt-20">
                          <div>
                            {imagePreview && (
                              <img
                                src={imagePreview}
                                alt="Preview"
                                width="200"
                                height="200"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-end">
            <div className="col-lg-10">
              {pageError && (
                <div className="alert alert-danger" role="alert">
                  {pageError}
                </div>
              )}
              <button type="submit" className="btn btn-primary">
                Add Content Page
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddTestimonial;
