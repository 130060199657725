import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Editor } from "@tinymce/tinymce-react";
import {
  getRequest,
  postFormDataRequest,
  postRequest,
} from "../../helpers/api_helper1";

const Add_Tax_Case = () => {
  const [formData, setFormData] = useState({
    appeal_number: "",
    filed_by: "",
    appellant: "",
    respondent: "",
    assessment_year: "",
    bench: "",
    case_status: "",
    filed_on: "",
    date_of_last_hearing: "",
    date_of_next_hearing: "",
    order_type: "",
    date_of_order: "",
    pronounced_on: "",
    result: "",
    case_summary: "",
    document: "",
    status_remarks: "",
    income_tax_case_id: "",
  });

  const {
    appeal_number,
    filed_by,
    appellant,
    respondent,
    assessment_year,
    bench,
    case_status,
    filed_on,
    date_of_last_hearing,
    date_of_next_hearing,
    order_type,
    date_of_order,
    pronounced_on,
    result,
    case_summary,
    document,
    status_remarks,
    income_tax_case_id,
  } = formData;

  const [image, setImage] = useState();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const [urlValue, setUrlValue] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [managePageData, setManagePageData] = useState();
  const [pageError, setPageError] = useState("");

  const { id } = useParams();

  useEffect(() => {
    getRequest({ url: "/admin/get-page-and-content" })
      .then((response) => {
        setManagePageData(response.data.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
    if (id) {
    //   fetchPrefilled(id);
    }
  }, []);

  const fetchPrefilled = async (id) => {
    let param = { id: id };
    try {
      const enquiryResponse = await getRequest({
        url: `/admin/get-manage-content-details`,
        params: param,
      });

      const enquiryRes = JSON.parse(JSON.stringify(enquiryResponse));
      console.log(enquiryRes, "res");

      if (enquiryRes.status == 200) {
        // setIsLoading(false);
        setFormData({
          ...formData,
          parent_link: enquiryRes.data.parent_link,
          page_name: enquiryRes.data.page_name,
          page_title: enquiryRes.data.page_title,
          page_url: enquiryRes.data.page_url,
          short_description: enquiryRes.data.short_description,
          description: enquiryRes.data.description,
          page_slider_description: enquiryRes.data.page_slider_description,
          in_menu: enquiryRes.data.in_menu ? enquiryRes.data.in_menu : "",
          menu_order: enquiryRes.data.menu_order
            ? enquiryRes.data.menu_order
            : "",
          // page_image: enquiryRes.data.page_image,
        });

        setImagePreview(enquiryRes.data.page_image);
      }
    } catch (error) {}
  };

  const handleEdiorChangeShortDescription = (content, editor) => {
    setFormData({ ...formData, short_description: content });
  };

  const handleEditorChangePageSlider = (content, editor) => {
    setFormData({ ...formData, page_slider_description: content });
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const sampledata = [];
  managePageData?.forEach((item) => {
    sampledata?.push({ value: item.id, label: item.page_name });
  });

  const validateForm = () => {
    let errors = {};
    // check for empty values

    // if (!formData.parent_link) {
    //   errors.parent_link = "Please select parent link";
    // }
    if (!formData.appeal_number) {
      errors.appeal_number = "Please enter Appeal Number";
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();

    formData.page_url = urlValue;
    const form = new FormData();
    form.append("appeal_number", appeal_number);
    form.append("filed_by", filed_by);
    form.append("appellant", appellant);
    form.append("respondent", respondent);
    form.append("assessment_year", assessment_year);
    form.append("bench", bench);
    form.append("case_status", case_status);
    form.append("filed_on", filed_on);
    form.append("date_of_last_hearing", date_of_last_hearing);
    form.append("date_of_next_hearing", date_of_next_hearing);
    form.append("order_type", order_type);
    form.append("date_of_order", date_of_order);
    form.append("pronounced_on", pronounced_on);
    form.append("result", result);
    form.append("case_summary", case_summary);
    form.append("status_remarks", status_remarks);
    form.append("income_tax_case_id", income_tax_case_id);
    form.append("document", document);

    console.log(form,Object.keys(errors),"form")

    if (Object.keys(errors).length === 0) {
      if (!id) {
        await postFormDataRequest({
          url: "/admin/add-edit-income-tax-case",
          data: form,
        })
          .then((response) => {
            console.log(response.status);
            if (response.status === 200) {
              navigate("/manage-content-page");
              toast.success("Added successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.result);
          });
      } else {
        form.append("id", id);
        form.append("status", "update");

        await postRequest({ url: "/admin/add-edit-income-tax-case", data: form })
          .then((response) => {
            console.log(response.status);
            if (response.status === 200) {
              navigate("/manage-content-page");
                toast.success("Added successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.data.result);
          });
      }
    } else {
      setErrors(errors);
      console.log(errors);
    }
    console.log(formData, "form");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    if (name === "document") {
      setImage(event.target.files[0]);
      //setFormData({ ...formData, page_image: event.target.files[0] });
      console.log(event.target.files, "event.target.files[0]");
      const selectedImage = event.target.files[0];
      if (!selectedImage) {
        setImagePreview(null);
        return;
      }
      const objectUrl = URL.createObjectURL(selectedImage);
      setImagePreview(objectUrl);
    }

    if (name == "page_name") {
      const url = value
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .replace(/\s+/g, "-");
      setUrlValue(url);

      setFormData({ ...formData, [name]: value, page_url: url });
      setErrors({ ...errors, [name]: "", page_url: "" });
    }
  };

  const selectStyles = (errorFieldName) => ({
    control: (provided) => ({
      ...provided,
      borderColor: errors[errorFieldName] ? "#dc3545" : provided.borderColor,
      boxShadow: errors[errorFieldName]
        ? "0 0 0 0.2rem rgba(220, 53, 69, 0.25)"
        : provided.boxShadow,
      minHeight: 22,
      height: 33,
    }),
  });

  const handleSelect = (fieldName, selectedOption) => {
    // setSelectedOption(selectedOption);
    console.log(selectedOption, "opton");
    setFormData({ ...formData, [fieldName]: selectedOption.value });
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };

  const in_Menu_Options = [
    { value: 0, label: "Not Apply" },
    { value: 1, label: "Header" },
    { value: 2, label: "Fotter" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <Container fluid={true}> */}
        <Breadcrumbs title="Manage Tax Case" breadcrumbItem="Add Case" />
        {/* <form onSubmit={handleSubmit} enctype="multipart/form-data" method="post"> */}
        <div data-repeater-list="outer-group" className="outer">
          <div data-repeater-item className="outer">
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
                Appeal Number
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="appeal_number"
                  type="text"
                  value={appeal_number}
                  className={
                    errors.appeal_number
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                  placeholder="Enter Appeal Number"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {errors.appeal_number && (
                  <div className="text-danger">{errors.appeal_number}</div>
                )}
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
                Filed By
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="filed_by"
                  type="text"
                  value={filed_by}
                  className={
                    errors.filed_by
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                  placeholder="Enter Filled by"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {errors.filed_by && (
                  <div className="text-danger">{errors.filed_by}</div>
                )}
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
                Appellant
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="appellant"
                  type="text"
                  value={appellant}
                  className={"form-control "}
                  placeholder="Enter Appellant Name.."
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
               Respondant
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="respondent"
                  value={respondent}
                  placeholder="Enter Respondent"
                  onChange={handleInputChange}
                  type="text"
                  className={
                    errors.respondent
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors.respondent && (
                  <div className="text-danger">{errors.respondent}</div>
                )}
              </div>
            </div>

            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
                Assessment Year
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="assessment_year"
                  type="date"
                  value={assessment_year}
                  className={
                    errors.assessment_year
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                  placeholder="Enter Assessment Year"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {errors.assessment_year && (
                  <div className="text-danger">{errors.assessment_year}</div>
                )}
              </div>
            </div>

            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
                Bench
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="bench"
                  type="text"
                  value={bench}
                  className={"form-control "}
                  placeholder="Enter Bench..."
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row mb-4">
              <label className="col-form-label col-lg-2">
               Case Status
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="case_status"
                  type="text"
                  value={case_status}
                  className={
                    errors.case_status
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                  placeholder="Enter Filled by"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {errors.case_status && (
                  <div className="text-danger">{errors.case_status}</div>
                )}
              </div>
            </div>
            <div className="form-group row mb-4">
              <label className="col-form-label col-lg-2">Filled On</label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="filed_on"
                  type="date"
                  value={filed_on}
                  className={
                    errors.filed_on
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                  placeholder="Enter Filed On"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {errors.filed_on && (
                  <div className="text-danger">{errors.filed_on}</div>
                )}
              </div>
            </div>

            <div className="form-group row mb-4">
              <label className="col-form-label col-lg-2">
              Date Of Last Hearing
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="date_of_last_hearing"
                  type="date"
                  value={date_of_last_hearing}
                  className={
                    errors.date_of_last_hearing
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                  placeholder="Enter Date Of Last Hearing"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {errors.date_of_last_hearing && (
                  <div className="text-danger">{errors.date_of_last_hearing}</div>
                )}
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
               Date Of Next Hearing
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="date_of_next_hearing"
                  type="date"
                  value={date_of_next_hearing}
                  className={
                    errors.date_of_next_hearing
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                  placeholder="Enter Date Of Next Hearing"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {errors.date_of_next_hearing && (
                  <div className="text-danger">{errors.date_of_next_hearing}</div>
                )}
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
               Order Type
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="order_type"
                  value={order_type}
                  placeholder="Enter Order Type"
                  onChange={handleInputChange}
                  type="text"
                  className={
                    errors.order_type
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors.order_type && (
                  <div className="text-danger">{errors.order_type}</div>
                )}
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
               Date Of Order
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="date_of_order"
                  value={date_of_order}
                  placeholder="Enter Date Of Order"
                  onChange={handleInputChange}
                  type="date"
                  className={
                    errors.date_of_order
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors.date_of_order && (
                  <div className="text-danger">{errors.date_of_order}</div>
                )}
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
               Pronounced On
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="pronounced_on"
                  value={pronounced_on}
                  placeholder="Enter Pronounced Date"
                  onChange={handleInputChange}
                  type="date"
                  className={
                    errors.pronounced_on
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors.pronounced_on && (
                  <div className="text-danger">{errors.pronounced_on}</div>
                )}
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
               Result
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="result"
                  value={result}
                  placeholder="Enter Result"
                  onChange={handleInputChange}
                  type="text"
                  className={
                    errors.result
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors.result && (
                  <div className="text-danger">{errors.result}</div>
                )}
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
               Case Summary
              </label>
              <div className="col-lg-10">
                <textarea
                  id="taskname"
                  name="case_summary"
                  value={case_summary}
                  placeholder="Enter Case Summary"
                  onChange={handleInputChange}
                  type="text"
                  className={
                    errors.case_summary
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors.case_summary && (
                  <div className="text-danger">{errors.case_summary}</div>
                )}
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
               Status Remarks
              </label>
              <div className="col-lg-10">
                <textarea
                  id="taskname"
                  name="status_remarks"
                  value={status_remarks}
                  placeholder="Enter Status Remarks"
                  onChange={handleInputChange}
                  type="text"
                  className={
                    errors.status_remarks
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors.status_remarks && (
                  <div className="text-danger">{errors.status_remarks}</div>
                )}
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
               Income Tax Case Id
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="income_tax_case_id"
                  value={income_tax_case_id}
                  placeholder="Enter PageUrl"
                  onChange={handleInputChange}
                  type="text"
                  className={
                    errors.income_tax_case_id
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors.income_tax_case_id && (
                  <div className="text-danger">{errors.income_tax_case_id}</div>
                )}
              </div>
            </div>


            <div className="inner-repeater mb-4">
              <div
                data-repeater-list="inner-group"
                className="inner form-group mb-0 row"
              >
                <label className="col-form-label col-lg-2">document</label>
                <div data-repeater-item className="inner col-lg-10 ms-md-auto">
                  <div className="mb-3 row align-items-center">
                    <div className="col-md-12">
                      <div className="mt-4 mt-md-0">
                        <input
                          className={"form-control "}
                          name="document"
                          type="file"
                          accept="pdf/*"
                          onChange={(e) => {
                            handleImageChange(e);
                            handleInputChange(e);
                          }}
                        />
                        {/* { errors.page_image && (
                                          <div className="text-danger">
                                            { errors.page_image}
                                          </div>
                                        )} */}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mt-4 mt-20">
                        <div>
                          {imagePreview && (
                            <img
                              src={imagePreview}
                              alt="Preview"
                              width="200"
                              height="200"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-lg-10">
              {pageError && (
                <div className="alert alert-danger" role="alert">
                  {pageError}
                </div>
              )}

              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Add Case
              </button>
            </div>
          </div>
          {/* </Container> */}
        </div>
        {/* </form> */}
      </div>
    </React.Fragment>
  );
};

export default Add_Tax_Case;
