import React, { useEffect } from "react";
import Routes from "./Routes/index";
import "./App.css";
import "./Assets/scss/theme.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getRequest } from "./helpers/api_helper1";
import { Navigate } from "react-router-dom";

function App() {
  useEffect(() => {
    ViewCompanyProfile();
  }, []);

  const ViewCompanyProfile = async () => {
    const res = await getRequest({ url: "/admin/get-company-profile" });
    if (res.status == 200) {
      console.log("200");
      Navigate("/dashboard");
    } else if (res.status == 401) {
      localStorage.removeItem("clearLaw_token");
      localStorage.removeItem("authUser");
      Navigate("/login");
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Routes />
    </React.Fragment>

    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Don't tell me what to do.
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
