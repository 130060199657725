import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import RadialChart1 from "./userpanelChart1";
import RadialChart2 from "./userpanelChart2";
import RadialChart3 from "./userpanelChart3";
import { getRequest } from "../../helpers/api_helper1";
import { useNavigate } from "react-router-dom";

const UserPanel = () => {
  const navigate = useNavigate();

  const [listPageData, setListPageData] = useState();
  const [mangeSlider, setMangeSlider] = useState();
  const [token, setToken] = useState(localStorage.getItem("cms_token"));
  const [seoPage, setSeoPage] = useState();
  const [faqPage, setFaqPage] = useState();
  const [dashboardData, setDashboardData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [freeUserList, setFreeUserList] = useState([]);
  const [paidUserList, setPaidUserList] = useState([]);
  const [recentApplyTradeList, setRecentApplyTradeList] = useState([]);
  const [recentEnqList, setRecentEnqList] = useState([]);
  const [recentGuestUser, setRecentGuestUser] = useState([]);

  const getDashboardData = async () => {
    setIsLoading(true);
    // let param = {
    //   start_date: startDate
    //     ? moment(startDate).utc().add(1, "d").format("DD-MM-YYYY")
    //     : "",
    //   endDate: endDate
    //     ? moment(endDate).utc().add(1, "d").format("DD-MM-YYYY")
    //     : "",
    // };
    // getRequest({ url: "/admin/get-page-and-content" })
    getRequest({ url: "/admin/get-dashboard-data" })
      .then((response) => {
        console.log(response, "res");
        setDashboardData(response);
        setFreeUserList(response?.free_trial_user_list);
        setPaidUserList(response?.paid_user_list);
        setRecentApplyTradeList(response?.recent_apply_trademark_list);
        setRecentEnqList(response?.recent_enquiry_list);
        setRecentGuestUser(response?.recent_gust_user);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/cms/login");
        }
      });
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    {/* <RadialChart1 /> */}
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Total Customers</p>
                  <h5 className="mb-3">{dashboardData?.total_user}</h5>
                  {/* <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      0.02%{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3 align-self-center">
                  {/* <RadialChart2
                    id="radialchart-2"
                    className="apex-charts"
                    dir="ltr"
                  /> */}
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Total Paid Customer</p>
                  <h5 className="mb-3">{dashboardData?.total_paid_user}</h5>
                  {/* <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      1.7%{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  {/* <RadialChart3
                    id="radialchart-3"
                    className="apex-charts"
                    dir="ltr"
                  /> */}
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Total Trial Customers</p>
                  <h5 className="mb-3">
                    {dashboardData?.total_free_trial_user}
                  </h5>
                  {/* <p className="text-truncate mb-0">
                    <span className="text-danger me-2">
                      {" "}
                      0.01%{" "}
                      <i className="ri-arrow-right-down-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  {/* <RadialChart3
                    id="radialchart-3"
                    className="apex-charts"
                    dir="ltr"
                  /> */}
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Total Web User  </p>
                  <h5 className="mb-3">{dashboardData?.total_web_user}</h5>
                  {/* <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      0.01%{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  {/* <RadialChart3
                    id="radialchart-3"
                    className="apex-charts"
                    dir="ltr"
                  /> */}
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Total Api Hits </p>
                  <h5 className="mb-3">{dashboardData?.total_api_hit}</h5>
                  {/* <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      0.01%{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  {/* <RadialChart3
                    id="radialchart-3"
                    className="apex-charts"
                    dir="ltr"
                  /> */}
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Total Amount Added </p>
                  <h5 className="mb-3">{dashboardData?.wallet_summary?.total_added_amount}</h5>
                  {/* <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      0.01%{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  {/* <RadialChart3
                    id="radialchart-3"
                    className="apex-charts"
                    dir="ltr"
                  /> */}
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Total Used Amount </p>
                  <h5 className="mb-3">
                    {dashboardData?.wallet_summary?.total_used_amount}
                  </h5>
                  {/* <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      0.01%{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <RadialChart3
                    id="radialchart-3"
                    className="apex-charts"
                    dir="ltr"
                  />
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Wallet Balance </p>
                  <h5 className="mb-3">
                    {dashboardData?.wallet_summary?.total_amount}
                  </h5>
                 
                </div>
              </div>
            </CardBody>
          </Card>
        </Col> */}
      </Row>
    </React.Fragment>
  );
};

export default UserPanel;
