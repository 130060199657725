import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import logolight from "../../Assets/images/ClearLaw.png";
import logodark from "../../Assets/images/ClearLaw.png";
import { postRequest } from "../../helpers/api_helper1";

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

//redux
// import { useSelector, useDispatch } from "react-redux";

import { Link, Navigate, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";



const Login = (props) => {
    const [error, setError] = useState('')
  let navigate = useNavigate();

  document.title = "Login | Upzet - React Admin & Dashboard Template";

  //   const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "rama@gmail.com" || "",
      password: "Test@123" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async(values) => {
      console.log(values, "values");
        try {
          const data = await postRequest({ url: "/auth/cms-login",data:values });
          if(data?.status == 200){
            console.log("Fetched data:", data);
            localStorage.setItem("authUser", JSON.stringify((data?.user_data)));
            localStorage.setItem("clearLaw_token",  JSON.stringify(data?.user_data?.auth_token));
            navigate("/dashboard");
          }
          else{
            console.log("err data:", data);
            setError(data?.response?.data?.error)
          }
        } catch (error) {
          
          console.error("Error fetching data:", error);
        };

    },
  });

  //   const { error } = useSelector(state => ({
  //     error: state.login.error,
  //   }));

//   const { error } = {
//     error: "error",
//   };

  //   handleValidSubmit
  //   const handleValidSubmit = (event, values) => {
  //    Navigate('dasboard');
  //   };

  //   const signIn = (res, type) => {
  //     if (type === "google" && res) {
  //       const postData = {
  //         name: res.profileObj.name,
  //         email: res.profileObj.email,
  //         token: res.tokenObj.access_token,
  //         idToken: res.tokenId,
  //       };
  //       dispatch(socialLogin(postData, props.router.navigate, type));
  //     } else if (type === "facebook" && res) {
  //       const postData = {
  //         name: res.name,
  //         email: res.email,
  //         token: res.accessToken,
  //         idToken: res.tokenId,
  //       };
  //       dispatch(socialLogin(postData, props.router.navigate, type));
  //     }
  //   };

  //handleGoogleLoginResponse
  //   const googleResponse = response => {
  //     signIn(response, "google");
  //   };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  //   const facebookResponse = response => {
  //     signIn(response, "facebook");
  //   };

  useEffect(() => {
    document.body.className = "bg-pattern";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="bg-overlay"></div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xl={4}>
              <Card>
                <CardBody className="p-4">
                  <div>  
                    <div className="text-center">
                      <Link to="/">
                        <img
                          src={logodark}
                          alt=""
                          height="24"
                          className="auth-logo logo-dark mx-auto"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="24"
                          className="auth-logo logo-light mx-auto"
                        />
                      </Link>
                    </div>
                    <h4 className="font-size-18 text-muted mt-2 text-center">
                      Welcome Back !
                    </h4>
                    <p className="mb-5 text-center">
                      Sign in to continue to Clear Law.
                    </p>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        // return false;
                      }}
                    >
                      {error ? (
                        <Alert color="danger">
                          <div>{error}</div>
                        </Alert>
                      ) : null}
                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.email}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                <div> {validation.errors.password} </div>
                              </FormFeedback>
                            ) : null}
                          </div>

                          <Row>
                            <Col>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                />
                                <label
                                  className="form-label form-check-label"
                                  htmlFor="customControlInline"
                                >
                                  Remember me
                                </label>
                              </div>
                            </Col>
                            <Col className="col-7">
                              <div className="text-md-end mt-3 mt-md-0">
                                <Link
                                  to="/auth-recoverpw"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock"></i> Forgot your
                                  password?
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <div className="d-grid mt-4">
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                         
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p className="text-white-50">
                  Don't have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Register{" "}
                  </Link>{" "}
                </p> */}
                <p className="text-white-50">
                  © {new Date().getFullYear()} Clear Law.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
