import React from "react";
import { Navigate } from "react-router-dom";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import Dashboard from "../Pages/Dashboard";
import UserProfile from "../Pages/Authentication/user-profile";
import Manage_Content_Page from "../Pages/Manage Content Page";
import Logout from "../Pages/Authentication/Logout";
import AddPageContent from "../Pages/Manage Content Page/add_page_content";
import Manage_Sliders from "../Pages/Manage Sliders";
import Manage_Seo from "../Pages/Manage Seo";
import Manage_Testimonial from "../Pages/Manage Testimonials";
import Manage_Subscribe from "../Pages/Manage Subscribe";
import AddSliders from "../Pages/Manage Sliders/add_sliders";
import AddSeoPage from "../Pages/Manage Seo/add_seo_content";
import AddTestimonial from "../Pages/Manage Testimonials/add_testimonial";
import AddSubsribe from "../Pages/Manage Subscribe/add_subscribe";
import Manage_Sponser from "../Pages/Manage Sponser";
import AddSponser from "../Pages/Manage Sponser/add_sponser";
import Manage_Enquiry from "../Pages/Manage Enquiry";
import Manage_Company_information from "../Pages/Manage Company Information";
import Manage_Tax_Case from "../Pages/Manage Tax Case";
import Add_Tax_Case from "../Pages/Manage Tax Case/add_tax_case";
import Manage_User from "../Pages/Manage User";
import AddUser from "../Pages/Manage User/add_user";
import Manage_Subscription from "../Pages/Manage Subscription/Index";
import ViewUserDetail from "../Pages/Manage User/ViewUserList";
import AddSubscriptionPlan from "../Pages/Manage Subscription/AddPlan";
import AddPlan from "../Pages/Manage Subscription/AddPlan";
import EditPlan from "../Pages/Manage Subscription/EditPlan";

const publicRoutes = [{ path: "/login", component: <Login /> },

{ path: "/logout", component: <Logout /> },

];

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },

  // Manage Company Information
  { path: "/manage-company-information", component: <Manage_Company_information/> },
 
  // Manage Content Page
  { path: "/manage-content-page", component: <Manage_Content_Page/> },

 // Add Content Page
 { path: "/add-content-page", component: <AddPageContent/> },

 // Edit Content Page
 { path: "/edit-content-page/:id", component: <AddPageContent/> },

  // Manage Sliders
  { path: "/manage-sliders", component: <Manage_Sliders/> },
  
 // Add Sliders Page
 { path: "/add-slider", component: <AddSliders/> },
   
 // Edit Sliders Page
 { path: "/edit-slider/:id", component: <AddSliders/> },
  
  // Manage Seo
  { path: "/manage-seo", component: <Manage_Seo/> },
  
 // Add Seo Page
 { path: "/add-seo", component: <AddSeoPage/> },
 
 // Edit Seo Page
 { path: "/edit-seo/:id", component: <AddSeoPage/> },
  
  // Manage Testimonial
  { path: "/manage-testimonials", component: <Manage_Testimonial/> },
  
 // Add Testimonial Page
 { path: "/add-testimonial", component: <AddTestimonial/> },
  
 // Edit Testimonial Page
 { path: "/edit-testimonial/:id", component: <AddTestimonial/> },
  
  // Manage Subscribe
  { path: "/manage-subscribe", component: <Manage_Subscribe/> },

    // Manage Subscription
    { path: "/manage-subscription", component: <Manage_Subscription/> },

    { path: "/manage-subscription/add-plan", component: <AddPlan/> },

    { path: "/manage-subscription/edit-plan/:id", component: <EditPlan/> },


  
 // Add Subscribe
 { path: "/add-subscribe", component: <AddSubsribe/> },

  // Manage Sponser
  { path: "/manage-sponser", component: <Manage_Sponser/> },
  
 // Add Sponser Page
 { path: "/add-sponser", component: <AddSponser/> },
 
 // Edit Sponser Page
 { path: "/edit-sponser/:id", component: <AddSponser/> },

 // Manage Enquiry
 { path: "/manage-enquiry", component: <Manage_Enquiry/> },

 // Manage Tax Case
 { path: "/manage-tax-case", component: <Manage_Tax_Case/> },

 // Add Tax Case
 { path: "/add-tax-case", component: <Add_Tax_Case/> },

 // Manage User
 { path: "/manage-user", component: <Manage_User/> },

 // Add User
 { path: "/add-user", component: <AddUser/> },

 { path: "/manage-user/view-user/:id", component: <ViewUserDetail/> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

export { publicRoutes, authProtectedRoutes };
