import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Editor } from "@tinymce/tinymce-react";
import {
  getRequest,
  postFormDataRequest,
  postRequest,
} from "../../helpers/api_helper1";

const AddPageContent = () => {
  document.title = "Form Elements | Upzet - React Admin & Dashboard Template";
  const [formData, setFormData] = useState({
    parent_link: "",
    page_name: "",
    page_title: "",
    page_url: "",
    short_description: "",
    description: "",
    page_slider_description: "",
    page_image: "",
    in_menu: "",
    menu_order: "",
  });

  const {
    parent_link,
    page_name,
    page_title,
    page_url,
    short_description,
    description,
    page_slider_description,
    page_image,
    in_menu,
    menu_order,
  } = formData;

  const [image, setImage] = useState();
  console.log(formData.page_image, "image");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const [urlValue, setUrlValue] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [managePageData, setManagePageData] = useState();
  const [pageError, setPageError] = useState("");

  const { id } = useParams();

  console.log(id, "id");

  useEffect(() => {
    getRequest({ url: "/admin/get-page-and-content" })
      .then((response) => {
        setManagePageData(response.data.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
    if (id) {
      fetchPrefilled(id);
    }
  }, []);

  const fetchPrefilled = async (id) => {
    let param = { id: id };
    try {
      const enquiryResponse = await getRequest({
        url: `/admin/get-manage-content-details`,
        params: param,
      });

      const enquiryRes = JSON.parse(JSON.stringify(enquiryResponse));
      console.log(enquiryRes, "res");

      if (enquiryRes.status == 200) {
        // setIsLoading(false);
        setFormData({
          ...formData,
          parent_link: enquiryRes.data.parent_link,
          page_name: enquiryRes.data.page_name,
          page_title: enquiryRes.data.page_title,
          page_url: enquiryRes.data.page_url,
          short_description: enquiryRes.data.short_description,
          description: enquiryRes.data.description,
          page_slider_description: enquiryRes.data.page_slider_description,
          in_menu: enquiryRes.data.in_menu ? enquiryRes.data.in_menu : "",
          menu_order: enquiryRes.data.menu_order
            ? enquiryRes.data.menu_order
            : "",
          // page_image: enquiryRes.data.page_image,
        });

        setImagePreview(enquiryRes.data.page_image);
      }
    } catch (error) {}
  };

  const handleEdiorChangeShortDescription = (content, editor) => {
    setFormData({ ...formData, short_description: content });
  };

  const handleEditorChangePageSlider = (content, editor) => {
    setFormData({ ...formData, page_slider_description: content });
  };

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  const sampledata = [];
  managePageData?.forEach((item) => {
    sampledata?.push({ value: item.id, label: item.page_name });
  });

  const validateForm = () => {
    let errors = {};
    // check for empty values

    // if (!formData.parent_link) {
    //   errors.parent_link = "Please select parent link";
    // }
    if (!formData.page_name) {
      errors.page_name = "Please enter Page Name";
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();

    formData.page_url = urlValue;
    const form = new FormData();
    form.append("parent_link", parent_link);
    form.append("page_name", page_name);
    form.append("page_title", page_title);
    form.append("page_url", page_url);
    form.append("in_menu", in_menu);
    form.append("menu_order", menu_order);
    form.append("short_description", short_description);
    form.append("description", description);
    form.append("page_slider_description", page_slider_description);
    form.append("page_image", image);

    if (Object.keys(errors).length === 0) {
      if (!id) {
        await postFormDataRequest({
          url: "/admin/add-page-and-content",
          data: form,
        })
          .then((response) => {
            console.log(response.status);
            if (response.status === 200) {
              navigate("/manage-content-page");
              toast.success("Added successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.result);
          });
      } else {
        form.append("id", id);
        form.append("status", "update");

        await postRequest({ url: "/admin/add-page-and-content", data: form })
          .then((response) => {
            console.log(response.status);
            if (response.status === 200) {
              navigate("/manage-content-page");
                toast.success("Added successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            setPageError(error.response.data.result);
          });
      }
    } else {
      setErrors(errors);
      console.log(errors);
    }
    console.log(formData, "form");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    if (name === "image") {
      setImage(event.target.files[0]);
      //setFormData({ ...formData, page_image: event.target.files[0] });
      console.log(event.target.files, "event.target.files[0]");
      const selectedImage = event.target.files[0];
      if (!selectedImage) {
        setImagePreview(null);
        return;
      }
      const objectUrl = URL.createObjectURL(selectedImage);
      setImagePreview(objectUrl);
    }

    if (name == "page_name") {
      const url = value
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .replace(/\s+/g, "-");
      setUrlValue(url);

      setFormData({ ...formData, [name]: value, page_url: url });
      setErrors({ ...errors, [name]: "", page_url: "" });
    }
  };

  const selectStyles = (errorFieldName) => ({
    control: (provided) => ({
      ...provided,
      borderColor: errors[errorFieldName] ? "#dc3545" : provided.borderColor,
      boxShadow: errors[errorFieldName]
        ? "0 0 0 0.2rem rgba(220, 53, 69, 0.25)"
        : provided.boxShadow,
      minHeight: 22,
      height: 33,
    }),
  });

  const handleSelect = (fieldName, selectedOption) => {
    // setSelectedOption(selectedOption);
    console.log(selectedOption, "opton");
    setFormData({ ...formData, [fieldName]: selectedOption.value });
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) {
      setImagePreview(null);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setImagePreview(objectUrl);
  };

  const in_Menu_Options = [
    { value: 0, label: "Not Apply" },
    { value: 1, label: "Header" },
    { value: 2, label: "Fotter" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <Container fluid={true}> */}
        <Breadcrumbs title="Manage Page Content" breadcrumbItem="Add Page" />
        {/* <form onSubmit={handleSubmit} enctype="multipart/form-data" method="post"> */}
        <div data-repeater-list="outer-group" className="outer">
          <div data-repeater-item className="outer">
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
                Parent Link
              </label>
              <div className="col-lg-10">
                <Select
                  className={`custom-select custom-select-sm`}
                  options={sampledata}
                  value={sampledata.filter(
                    (option) => option.value == parent_link
                  )}
                  styles={selectStyles("parent_link")}
                  name="parent_link"
                  onChange={(selectedOption) =>
                    handleSelect("parent_link", selectedOption)
                  }
                >
                  <option value="">Select an option</option>
                  {managePageData?.map((item) => (
                    <option value={item.id}>{item.page_name}</option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
                Page Name
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="page_name"
                  type="text"
                  value={page_name}
                  className={
                    errors.page_name
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                  placeholder="Enter Page Name..."
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {errors.page_name && (
                  <div className="text-danger">{errors.page_name}</div>
                )}
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
                Page Title
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="page_title"
                  type="text"
                  value={page_title}
                  className={"form-control "}
                  placeholder="Enter Page Title..."
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
                Page Url
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="page_url"
                  value={page_url}
                  placeholder="Enter PageUrl"
                  onChange={handleInputChange}
                  type="text"
                  className={
                    errors.page_url
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors.page_url && (
                  <div className="text-danger">{errors.page_url}</div>
                )}
              </div>
            </div>

            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
                In Menu
              </label>
              <div className="col-lg-10">
                <Select
                  className={`custom-select custom-select-sm`}
                  options={in_Menu_Options}
                  value={in_Menu_Options.filter(
                    (option) => option.value == in_menu
                  )}
                  styles={selectStyles("in_menu")}
                  name="in_menu"
                  onChange={(selectedOption) =>
                    handleSelect("in_menu", selectedOption)
                  }
                >
                  <option value="">Select an option</option>
                  {in_Menu_Options?.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="form-group row mb-4">
              <label for="taskname" className="col-form-label col-lg-2">
                Menu Order
              </label>
              <div className="col-lg-10">
                <input
                  id="taskname"
                  name="menu_order"
                  type="number"
                  value={menu_order}
                  className={"form-control "}
                  placeholder="Enter Menu Order..."
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-group row mb-4">
              <label className="col-form-label col-lg-2">
                Short Description
              </label>
              <div className="col-lg-10">
                <Editor
                     apiKey='qx79nm58es7l62j5sl8c3l99q6ctf6dqprrp48mcuj9pbhsz'
                  onEditorChange={handleEdiorChangeShortDescription}
                  value={short_description}
                  init={{
                    plugins:
                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                    table_default_attributes: {
                      border: "5",
                    },
                  }}
                />
                {/* {shortDescriptionError && (
                                <div className="text-danger">
                                  {shortDescriptionError}
                                </div>
                              )} */}
              </div>
            </div>
            <div className="form-group row mb-4">
              <label className="col-form-label col-lg-2">Description</label>
              <div className="col-lg-10">
                <Editor
                apiKey='qx79nm58es7l62j5sl8c3l99q6ctf6dqprrp48mcuj9pbhsz'
                  onEditorChange={handleEditorChangeDescription}
                  value={description}
                  init={{
                    plugins:
                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                    table_default_attributes: {
                      border: "5",
                    },
                  }}
                />
                {/* {descriptionError && (
                                  <div className="text-danger">
                                    {descriptionError}
                                  </div>
                                )} */}
              </div>
            </div>

            <div className="form-group row mb-4">
              <label className="col-form-label col-lg-2">
                Page Slider Description
              </label>
              <div className="col-lg-10">
                <Editor
                apiKey='qx79nm58es7l62j5sl8c3l99q6ctf6dqprrp48mcuj9pbhsz'
                  onEditorChange={handleEditorChangePageSlider}
                  value={page_slider_description}
                  init={{
                    plugins:
                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                    table_default_attributes: {
                      border: "5",
                    },
                  }}
                />
                {/* {pageSliderError && (
                                <div className="text-danger">
                                  {pageSliderError}
                                </div>
                              )} */}
              </div>
            </div>

            <div className="inner-repeater mb-4">
              <div
                data-repeater-list="inner-group"
                className="inner form-group mb-0 row"
              >
                <label className="col-form-label col-lg-2">Image</label>
                <div data-repeater-item className="inner col-lg-10 ms-md-auto">
                  <div className="mb-3 row align-items-center">
                    <div className="col-md-12">
                      <div className="mt-4 mt-md-0">
                        <input
                          className={"form-control "}
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            handleImageChange(e);
                            handleInputChange(e);
                          }}
                        />
                        {/* { errors.page_image && (
                                          <div className="text-danger">
                                            { errors.page_image}
                                          </div>
                                        )} */}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mt-4 mt-20">
                        <div>
                          {imagePreview && (
                            <img
                              src={imagePreview}
                              alt="Preview"
                              width="200"
                              height="200"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-lg-10">
              {pageError && (
                <div className="alert alert-danger" role="alert">
                  {pageError}
                </div>
              )}

              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Add Content Page
              </button>
            </div>
          </div>
          {/* </Container> */}
        </div>
        {/* </form> */}
      </div>
    </React.Fragment>
  );
};

export default AddPageContent;
