import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL, API_URL } from "../../config";
import axios from "axios";
import Loader from "../../components/Common/loader";
import { getRequest } from "../../helpers/api_helper1";

const ViewUserDetail = () => {
  const [formData, setFormData] = useState({
    pageurl: "",
    name: "",
    rating: "",
    metakeywords: "",
    description: "",
    cityandcountry: "",
    image_url: "",
  });
  const [pageError, setPageError] = useState("");
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(true);


  const navigate = useNavigate();
  const {
    pageurl,
    name,
    rating,
    metakeywords,
    description,
    cityandcountry,
    image_url,
  } = formData;
  const { id } = useParams();

  const [image, setImage] = useState("");
  const [userData, setUserData] = useState();
  const [walletData, setWalletData] = useState();
  const [apiCallsData, setApiCallsData] = useState();

  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    ViewPage();
  }, []);

  const ViewPage = async () => {
   setIsLoading(true);
   await getRequest({ url: "/admin/get-user-details", params: {user_id:id} })
   .then((res)=>{
        console.log(res)

    if (res.status == 200) {
        console.log(res)
        setUserData(res?.data)
        setWalletData(res?.wallet_details)
        setApiCallsData(res?.api_calls)
        setIsLoading(false)
      }
   })
   
  };
  let hasError = false;
  const handleSubmit = (event) => {
    event.preventDefault();
    // if (!formData.pageurl) {
    //   setPageurlError("Please enter page url");
    //   hasError = true;
    // } else {
    //   setPageurlError("");
    // }
    // if (!formData.pagename) {
    //   setPageNameError("Please enter pagename");
    //   hasError = true;
    // } else {
    //   setPageNameError("");
    // }
    
  }

  const handleEditorChangeDescription = (content, editor) => {
    setFormData({ ...formData, description: content });
  };

  
 
  return (
    <>
      {/* <div className="main-content"> */}
        <div className="page-content">
        {isLoading ? (
                     <div className="d-flex justify-content center text-center"> <Loader /> </div>
                    ) : (
          <div className="container-fluid">
          <section id="aff_prof_sec">
         <div class="container">
            <div class="card new_aff_card mt-5">
               <div class="row">
                  <div class="col-md-4 col-lg-4 pl-0">
                     <div class="aff_prof_box">
                        <div class="profl_detais pb-4">
                           <div class="text-center">
                              <img src="https://docsbizkit.com/admin/img/avatar.svg" class="img-thumbnail"/>
                           </div>
                           <h3>{userData?.name}</h3>
                           <h3>
                              <button class="btn btn-danger btn-sm">{userData?.status}</button>
                           </h3>
                           <div class="aff_con_text">
                              <p class="mt-3 mb-0">
                                 <i class="fa fa-phone me-2"></i>
                                 {userData?.mobile}
                              </p>
                              <p class="mt-3 mb-0">
                                 <i class="fa fa-envelope me-2"></i>
                                 <a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="deb7adb6bfb0b0b4b1adb6b7e9ed9eb9b3bfb7b2f0bdb1b3">{userData?.email}</a>
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-4 col-lg-4">
                     <div class="aff_prof_box">
                        <div class="card">
                           <div class="card-body">
                              <div class="bdb mb-2">
                                 <h2 class="card-title">Personal Information</h2>
                              </div>
                              <div class="table-responsive">
                                 <table class="table table-nowrap mb-0 table-baddresserless">
                                    <tbody>
                                       {/* <tr>
                                          <th scope="row">
                                             <h5 class="m-0">Status :</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">
                                                <select >
                                                   <option value="">--- Select Status ---</option>
                                                   <option value="https://docsbizkit.com/customers/status/176">Active</option>
                                                   <option value="https://docsbizkit.com/customers/status/176" selected>Inactive</option>
                                                </select>
                                             </p>
                                          </td>
                                       </tr> */}
                                       <tr>
                                          <th scope="row">
                                             <h5 class="m-0">Plan Name:</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">{userData?.plan_name}</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <th scope="row">
                                             <h5 class="m-0">Plan Type:</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">{userData?.plan_type}</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <th scope="row">
                                             <h5 class="m-0">Plan Status:</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">{userData?.status}</p>
                                          </td>
                                       </tr>
                                       <tr class="my-2">
                                          <th scope="row">
                                             <h5 class="m-0 font-size-14">Plan ID:</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">{userData?.plan_id}</p>
                                          </td>
                                       </tr>
                                       {/* <tr class="my-2">
                                          <th scope="row">
                                             <h5 class="m-0">Business Priority:</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13"></p>
                                          </td>
                                       </tr> */}
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-4 col-lg-4 pr-0">
                     <div class="aff_prof_box">
                        <div class="card">
                           <div class="card-body">
                              <div class="bdb mb-2">
                                 <h2 class="card-title">Plan Information</h2>
                              </div>
                              <div class="table-responsive">
                                 <table class="table table-nowrap mb-0 table-baddresserless">
                                    <tbody>
                                       <tr>
                                          <th scope="row">
                                             <h5 class="m-0">Subscription Start Date:</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">{userData?.subscription_start_date}</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <th scope="row">
                                             <h5 class="m-0">Subscription End Date:</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">{userData?.subscription_end_date}</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <th scope="row">
                                             <h5 class="m-0">Subscription Status:</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">{userData?.subscription_status}</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <th scope="row">
                                             <h5 class="m-0">Given Api Count:</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">{userData?.given_api_count}</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <th scope="row">
                                             <h5 class="m-0">Used Api Count:</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">{userData?.used_api_cont}</p>
                                          </td>
                                       </tr>
                                       
                                       {/* <tr class="my-2">
                                          <th scope="row">
                                             <h5 class="m-0 font-size-14">Total Document Download:</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">0</p>
                                          </td>
                                       </tr>
                                       <tr>
                                          <th scope="row">
                                             <h5 class="m-0">Total Fevorite:</h5>
                                          </th>
                                          <td>
                                             <p class="m-0 font-size-13">0</p>
                                          </td>
                                       </tr> */}
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section id="aff_tabs">
         <div class="container">
            <div class="tabs-wrapper">
               <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                     <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Api Calls</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Wallet Detail</button>
                  </li>
                  {/* <li class="nav-item" role="presentation">
                     <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Document Download History</button>
                  </li>
                  <li class="nav-item" role="presentation">
                     <button class="nav-link" id="pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#pills-disabled" type="button" role="tab" aria-controls="pills-disabled" aria-selected="false"   >Favourite</button>
                  </li> */}
               </ul>
               <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active card p-4" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                     <div class="table-responsive">
                        <table id="example" class="table table-striped" style={{width:'100%'}}>
                           <thead>
                              <tr>
                                 <th>ENd Point</th>
                                 <th>Searched From</th>
                                 <th>Searched Data</th>
                                 <th>Date - Time</th>
                              </tr>
                           </thead>
                           <tbody>
                             {apiCallsData?.map((item,idx)=>{
                              return(
                                 <>
                                  <tr>
                                 <td>{item?.api_end_point}</td>
                                 <td>{item?.search_from}</td>
                                 <td ><span title={item?.request_payload}>{item?.request_payload.substring(0,40)}</span></td>
                                 <td>{item?.updated_at}</td>
                              </tr>
                                 </>
                              )
                             })}
                           </tbody>
                        </table>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                     <div class="table-responsive">
                        <table id="example" class="table table-striped" style={{width:'100%'}}>
                           <thead>
                              <tr>
                                 <th>Date</th>
                                 <th>Amount</th>
                                 <th>Wallet Bal</th>
                              </tr>
                           </thead>
                           <tbody>
                            {walletData?.details?.map((item,idx)=>{
                              return(
                                 <>
                                  <tr>
                                 <td>{item?.transaction_date}</td>
                                 <td>{item?.credit ? `${item?.credit}` : `-${item?.debit}`}</td>
                                 <td>{item?.total_amount}</td>
                              </tr>
                                 </>
                              )
                            }) }
                           </tbody>
                        </table>
                     </div>
                  </div>
                  {/* <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
                     <div class="table-responsive">
                        <table id="example" class="table table-striped" style={{width:'100%'}}>
                           <thead>
                              <tr>
                                 <th>Name</th>
                                 <th>Position</th>
                                 <th>Office</th>
                                 <th>Age</th>
                                 <th>Start date</th>
                                 <th>Salary</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <td>Tiger Nixon</td>
                                 <td>System Architect</td>
                                 <td>Edinburgh</td>
                                 <td>61</td>
                                 <td>2011-04-25</td>
                                 <td>$320,800</td>
                              </tr>
                              <tr>
                                 <td>Garrett Winters</td>
                                 <td>Accountant</td>
                                 <td>Tokyo</td>
                                 <td>63</td>
                                 <td>2011-07-25</td>
                                 <td>$170,750</td>
                              </tr>
                              <tr>
                                 <td>Ashton Cox</td>
                                 <td>Junior Technical Author</td>
                                 <td>San Francisco</td>
                                 <td>66</td>
                                 <td>2009-01-12</td>
                                 <td>$86,000</td>
                              </tr>
                              <tr>
                                 <td>Cedric Kelly</td>
                                 <td>Senior Javascript Developer</td>
                                 <td>Edinburgh</td>
                                 <td>22</td>
                                 <td>2012-03-29</td>
                                 <td>$433,060</td>
                              </tr>
                              <tr>
                                 <td>Airi Satou</td>
                                 <td>Accountant</td>
                                 <td>Tokyo</td>
                                 <td>33</td>
                                 <td>2008-11-28</td>
                                 <td>$162,700</td>
                              </tr>
                              <tr>
                                 <td>Brielle Williamson</td>
                                 <td>Integration Specialist</td>
                                 <td>New York</td>
                                 <td>61</td>
                                 <td>2012-12-02</td>
                                 <td>$372,000</td>
                              </tr>
                              <tr>
                                 <td>Herrod Chandler</td>
                                 <td>Sales Assistant</td>
                                 <td>San Francisco</td>
                                 <td>59</td>
                                 <td>2012-08-06</td>
                                 <td>$137,500</td>
                              </tr>
                              <tr>
                                 <td>Rhona Davidson</td>
                                 <td>Integration Specialist</td>
                                 <td>Tokyo</td>
                                 <td>55</td>
                                 <td>2010-10-14</td>
                                 <td>$327,900</td>
                              </tr>
                              <tr>
                                 <td>Quinn Flynn</td>
                                 <td>Support Lead</td>
                                 <td>Edinburgh</td>
                                 <td>22</td>
                                 <td>2013-03-03</td>
                                 <td>$342,000</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="pills-disabled" role="tabpanel" aria-labelledby="pills-disabled-tab" tabindex="0">
                     <div class="table-responsive">
                        <table id="example" class="table table-striped" style={{width:'100%'}}>
                           <thead>
                              <tr>
                                 <th>Name</th>
                                 <th>Position</th>
                                 <th>Office</th>
                                 <th>Age</th>
                                 <th>Start date</th>
                                 <th>Salary</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <td>Tiger Nixon</td>
                                 <td>System Architect</td>
                                 <td>Edinburgh</td>
                                 <td>61</td>
                                 <td>2011-04-25</td>
                                 <td>$320,800</td>
                              </tr>
                              <tr>
                                 <td>Garrett Winters</td>
                                 <td>Accountant</td>
                                 <td>Tokyo</td>
                                 <td>63</td>
                                 <td>2011-07-25</td>
                                 <td>$170,750</td>
                              </tr>
                              <tr>
                                 <td>Ashton Cox</td>
                                 <td>Junior Technical Author</td>
                                 <td>San Francisco</td>
                                 <td>66</td>
                                 <td>2009-01-12</td>
                                 <td>$86,000</td>
                              </tr>
                              <tr>
                                 <td>Cedric Kelly</td>
                                 <td>Senior Javascript Developer</td>
                                 <td>Edinburgh</td>
                                 <td>22</td>
                                 <td>2012-03-29</td>
                                 <td>$433,060</td>
                              </tr>
                              <tr>
                                 <td>Airi Satou</td>
                                 <td>Accountant</td>
                                 <td>Tokyo</td>
                                 <td>33</td>
                                 <td>2008-11-28</td>
                                 <td>$162,700</td>
                              </tr>
                              <tr>
                                 <td>Brielle Williamson</td>
                                 <td>Integration Specialist</td>
                                 <td>New York</td>
                                 <td>61</td>
                                 <td>2012-12-02</td>
                                 <td>$372,000</td>
                              </tr>
                              <tr>
                                 <td>Herrod Chandler</td>
                                 <td>Sales Assistant</td>
                                 <td>San Francisco</td>
                                 <td>59</td>
                                 <td>2012-08-06</td>
                                 <td>$137,500</td>
                              </tr>
                              <tr>
                                 <td>Rhona Davidson</td>
                                 <td>Integration Specialist</td>
                                 <td>Tokyo</td>
                                 <td>55</td>
                                 <td>2010-10-14</td>
                                 <td>$327,900</td>
                              </tr>
                              <tr>
                                 <td>Quinn Flynn</td>
                                 <td>Support Lead</td>
                                 <td>Edinburgh</td>
                                 <td>22</td>
                                 <td>2013-03-03</td>
                                 <td>$342,000</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div> */}
               </div>
            </div>
         </div>
      </section>
          </div>)}
        </div>
      {/* </div> */}
    </>
  );
};

export default ViewUserDetail;
